.modal-popup {
    &.confirm {
        .modal-header {
            display: none;
        }
    }

    .modal-inner-wrap {
        .modal-header {
            padding: 1.5rem;

            .modal-title {
                border: 0;
                padding: 0;
            }

            .action-close {
                padding: 1rem;
            }
        }

        .modal-content {
            border: 0;
            border-radius: 0;
            padding: 1.5rem;
        }

        .modal-footer {
            padding: 1.5rem;
            align-items: flex-start;
            justify-content: space-between;

            .action {                
                &.action-save-address {
                    margin: 0;
                }
            }
        }
    }
}