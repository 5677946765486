.modal-add-to-cart__wrapper {
    @include media-breakpoint-between(xs,md) {
        left: 5rem !important;
    }

    .modal-inner-wrap {
        @include media-breakpoint-between(xs,md) {
            background-color: $light !important;
            transition: unset !important;
        }

        .modal-header {
            padding: 0;
            display: flex;
            align-items: center;
            border: 0;
            background: $primary;
            border-radius: 0;

            .modal-title {
                padding: 0;
                border: 0;
                font-weight: 500;
                margin-left: 15px;
                color: $white;

                &:before {
                    content: "";
                    mask-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='512px' height='376px' viewBox='0 0 512 376' style='enable-background:new 0 0 512 376;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M504.5,7.5c-10-10-26.2-10-36.2,0L161.6,314.2L43.7,196.3c-10-10-26.2-10-36.2,0c-10,10-10,26.2,0,36.2l136,136 c10,10,26.2,10,36.2,0L504.5,43.7C514.5,33.7,514.5,17.5,504.5,7.5z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                    mask-size: contain;
                    mask-repeat: no-repeat;
                    width: 18px;
                    height: 14px;
                    background: $white;
                    margin-right: .75rem;
                    display: inline-block;
                    max-width: 100%;
                    max-height: 100%;
                }

                @include media-breakpoint-up(xs) {
                    font-size: 1.5rem;
                }

                @include media-breakpoint-up(md) {
                    font-size: 2rem;
                }
            }

            .action-close {
                margin-left: auto;
                position: relative;
                padding: .7rem 1rem;

                &:before {
                    color: $white;
                }
            }
        }

        .modal-content {
            padding: 0;
            border: 0;
        }

        .modal-content__inner {
            @include media-breakpoint-up(md) {
                display: flex;
                align-items: center;
            }

            img {
                object-fit: contain;

                @include media-breakpoint-up(xs) {
                    height: 150px;
                }

                @include media-breakpoint-up(md) {
                    height: 200px;
                    padding: 0 !important;
                }
            }

            .modal-content_details {
                display: flex;
                flex-direction: column;

                .product-name {
                    font-size: 1.7rem;
                    font-weight: 600;
                    line-height: 1.2;
                }

                .product-price {
                    margin-top: 1rem;

                    .price {
                        font-size: 1.5rem;
                        font-weight: 500;
                    }
                }
            }

            .actions {
                a {
                    &:visited {
                        color: #fff;
                    }
                }

                .btn {
                    font-weight: 500;
                    padding: 10px 15px;
                }
            }
        }
    }

    .modal-add-to-cart {
        .products {
            @include media-breakpoint-up(md) {
                margin: 1rem -10px 2rem;
            }

            .product-item {
                @include media-breakpoint-up(md) {
                    width: 33.333%;
                }

                margin-left: 0 !important;

                &:not(:first-child) {
                    margin-left: 2%;
                }

                .product-img {
                    display: block;

                    img {
                        height: 150px;
                        width: 100%;
                        object-fit: contain;
                    }
                }
            }
        }
    }

    .modal-footer {
        display: flex;
        justify-content: space-between;
        padding: 1.5rem 2rem;

        .btn {
            padding: 1rem 1.5rem;
            margin: 0;
            font-weight: 600;

            &.btn-secondary {
                box-shadow: inset 0 -2px 0 0 rgba(0, 0, 0, 0.2);
            }
        }

        .btn-cta {
            background-color: #44bd00;
            border-color: #44bd00;
            color: #fff;
        }
    }

    .upsells-modal {
        padding: 0 30px;
        margin: 0;
    }
}
