.pagebuilder-column-group {
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(-#{$grid-gutter-width} * 1);
    margin-right: calc(-#{$grid-gutter-width} * 1);

    .pagebuilder-column {
        &:empty {
            display: none !important;
        }
    }
}

.page-layout-cms-full-width {
    .pagebuilder-column {
        margin: 1.5rem 0;
        padding: 0 15px;
    }
}
