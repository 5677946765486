.message {
    margin-top:    1rem !important;
    border-radius: .3rem;
    font-weight:   500;
    font-size:     1.4rem;

    @include media-breakpoint-up(xs) {
        padding: 1rem 1.5rem !important;
    }

    @include media-breakpoint-up(md) {
        padding: 2rem 2.5rem !important;
    }

    a {
        color: $dark !important;

        &:hover {
            text-decoration: underline;
        }
    }

    dl {
        &.block {
            padding:    0;
            margin-top: 1rem;

            .title {
                margin-bottom: 0.5rem;
            }

            dd {
                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    color: #6A5004 !important;
                }
            }
        }
    }

    &.info,
    &.notice,
    &.success,
    &.error,
    &.warning {
        & > :first-child {
            &:before {
                mask-size:     contain;
                mask-repeat:   no-repeat;
                mask-position: center;
                margin-top:    0;
                margin-right:  1rem;
                position:      relative;
                top:           -2px;
                font-size:     0;
            }
        }
    }

    &.info,
    &.notice,
    &.warning {
        color:            #6A5004;
        background-color: #FFBF00;
        border-color:     #FFBF00;

        & > :first-child {
            &:before {
                mask-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Laag_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='22.9px' height='20.1px' viewBox='0 0 22.9 20.1' style='enable-background:new 0 0 22.9 20.1;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%23000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;%7D%0A%3C/style%3E%3Cpath class='st0' d='M9.7,2L1.3,16.1c-0.6,1-0.2,2.2,0.7,2.7c0.3,0.2,0.6,0.3,1,0.3h16.9c1.1,0,2-0.9,2-2c0-0.3-0.1-0.7-0.3-1 L13.2,2c-0.6-0.9-1.8-1.2-2.7-0.7C10.1,1.5,9.9,1.7,9.7,2z'/%3E%3Cline class='st0' x1='11.4' y1='7.1' x2='11.4' y2='11.1'/%3E%3Cline class='st0' x1='11.4' y1='15.1' x2='11.4' y2='15.1'/%3E%3C/svg%3E%0A");
                width:      17px;
                height:     14px;
                mask-size:  contain;
                background: #6A5004;
            }
        }
    }

    &.success {
        background-color: #0FD50F;
        color:            #054D05;

        & > :first-child {
            &:before {
                content:    "";
                mask-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='512px' height='376px' viewBox='0 0 512 376' style='enable-background:new 0 0 512 376;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M504.5,7.5c-10-10-26.2-10-36.2,0L161.6,314.2L43.7,196.3c-10-10-26.2-10-36.2,0c-10,10-10,26.2,0,36.2l136,136 c10,10,26.2,10,36.2,0L504.5,43.7C514.5,33.7,514.5,17.5,504.5,7.5z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                width:      18px;
                height:     14px;
                mask-size:  contain;
                background: #034603;
            }
        }
    }

    &.error {
        margin-top:       0;
        background-color: #F43535;
        color:            #FFD0CF;

        & > :first-child {
            &:before {
                mask-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Laag_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='22.9px' height='20.1px' viewBox='0 0 22.9 20.1' style='enable-background:new 0 0 22.9 20.1;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%23000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;%7D%0A%3C/style%3E%3Cpath class='st0' d='M9.7,2L1.3,16.1c-0.6,1-0.2,2.2,0.7,2.7c0.3,0.2,0.6,0.3,1,0.3h16.9c1.1,0,2-0.9,2-2c0-0.3-0.1-0.7-0.3-1 L13.2,2c-0.6-0.9-1.8-1.2-2.7-0.7C10.1,1.5,9.9,1.7,9.7,2z'/%3E%3Cline class='st0' x1='11.4' y1='7.1' x2='11.4' y2='11.1'/%3E%3Cline class='st0' x1='11.4' y1='15.1' x2='11.4' y2='15.1'/%3E%3C/svg%3E%0A");
                width:      17px !important;
                height:     14px;
                mask-size:  contain;
                background: #FFD0CF;
            }
        }
    }
}
