body {
    &.catalog-product-view {
        background: $white;

        .messages {
            .message-success {
                display: none;
            }
        }

        .page-header {
            margin-bottom: 0;
        }
    }
}

.page-title-wrapper {
    position: relative;

    @include media-breakpoint-between(xs, md) {
        order: -2;
    }

    @include media-breakpoint-up(xs) {
        margin: 0.5rem 0 1.5rem 0;
    }

    @include media-breakpoint-up(md) {
        margin: 1.5rem 0 2rem 0;
    }

    &.product {
        .page-title {
            @include media-breakpoint-up(xs) {
                font-size: 2rem;
                margin-bottom: .5rem !important;
            }

            @include media-breakpoint-up(md) {
                max-width: 75%;
                font-size: 3rem;
                margin-bottom: 1rem !important;
            }

            @include media-breakpoint-up(lg) {
                max-width: 50%;
            }
        }
    }

    .attribute,
    .attr-brand {
        @include media-breakpoint-up(xs) {
            font-size: 1.4rem;
        }

        @include media-breakpoint-up(md) {
            font-size: 1.5rem;
        }
    }

    .attribute {
        display: inline-block;
        white-space: nowrap;

        .value {
            display: inline-block;
        }
    }

    .attr-brand {
        &:before {
            content: "|";
            margin-right: 0.5rem;
            position: relative;
            top: -1px;
        }
    }

    .amshopby-option-link {
        display: inline-flex;
        font-size: 1.5rem;

        &:before {
            content: "Merk";
            font-weight: 600;
            margin-right: 4px;
        }

        &:after {
            content: "|";
            margin-left: 0.5rem;
            position: relative;
            top: -1px;
        }

        .amshopby-brand-title-link {
            color: $dark;
        }

        .brand-title {
            ~ a {
                position: absolute;
                right: 0;

                @include media-breakpoint-up(xs) {
                    top: 100%;
                    z-index: 1;
                }

                @include media-breakpoint-up(lg) {
                    top: 0;
                }

                img {
                    margin: 0;
                }
            }
        }
    }
}

.breadcrumbs--wrap {
    @include media-breakpoint-between(xs, md) {
        background-color: transparent !important;
    }

    .breadcrumbs {
        .btn-back {
            &:hover {
                text-decoration: none;

                span {
                    &:before,
                    &:after {
                        background-color: $light;
                    }
                }
            }

            span {
                z-index: 1;
                padding-right: 1rem;
                padding-left: 20px;
                position: relative;
                display: block;

                @include media-breakpoint-up(xs) {
                    line-height: 30px;
                }

                @include media-breakpoint-up(md) {
                    line-height: 34px;
                }

                &:before,
                &:after {
                    content: "";
                    z-index: -2;
                    background-color: $white;
                    position: absolute;
                }

                &:before {
                    left: 13px;
                    right: 0;
                    border-radius: .375rem;
                    border-left-width: 0;
                    border: 1px solid #e3e3e3;
                    border-left-width: 1px;

                    @include media-breakpoint-up(xs) {
                        height: 32px;
                    }

                    @include media-breakpoint-up(md) {
                        height: 34px;
                    }
                }

                &:after {
                    border: 1px solid #e3e3e3;
                    left: 3px;
                    border-bottom-left-radius: .375rem;
                    border-right: 0 transparent;
                    border-top: 0 transparent;
                    top: 6px;
                    transform: rotate(45deg);

                    @include media-breakpoint-up(xs) {
                        width: 20px;
                        height: 20px;
                    }

                    @include media-breakpoint-up(md) {
                        width: 23px;
                        height: 23px;
                    }
                }
            }
        }
    }
}

.page-layout-1column {
    .product-info-main {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-between(xs, md) {
            margin-top: 3.5rem;
        }

        @include media-breakpoint-up(xs) {
            margin-bottom: 2.5rem;
        }

        @include media-breakpoint-up(md) {
            box-shadow: $box-shadow-lg;
            border-radius: $border-radius-sm;
            background-color: $white;
            padding: 3rem;
            margin-bottom: 5rem;
        }

        .product-info-price {
            order: 1;

            .price-box {
                margin: 0;

                .special-price__wrapper {
                    .old-price {
                        overflow: hidden;

                        .price {
                            font-weight: $font-weight-normal;
                            color: $dark;

                            @include media-breakpoint-up(xs) {
                                font-size: 1.5rem;
                                margin-left: -5px;
                            }

                            @include media-breakpoint-up(md) {
                                font-size: 1.8rem;
                                margin-left: -7px;
                            }
                        }

                        .price-final_price {
                            margin-bottom: 0;

                            .price-wrapper {
                                overflow: hidden;
                            }
                        }
                    }

                    .special-price {
                        margin: 0;
                    }


                    .price-wrapper {
                        line-height: 1;
                    }
                }

                .price-container {
                    line-height: 1;

                    .price-including-tax {
                        .price {
                            color: $black;

                            @include media-breakpoint-up(xs) {
                                font-size: 3rem;
                            }

                            @include media-breakpoint-up(md) {
                                font-size: 4rem;
                            }
                        }
                    }

                    .price-excluding-tax {
                        .price {
                            font-weight: 500;
                        }
                    }
                }
            }
        }

        .list {
            order: 2;
        }

        .prices-tier {
            padding: 0;
            margin-bottom: 0;
            background-color: transparent;

            .item {
                margin-bottom: 0;
                font-size: 1.5rem;
            }
        }

        .product-add-form {
            order: 3;

            form {
                display: flex;
                flex-direction: column;

                .product-options-wrapper {
                    margin-top: 1.5rem;

                    .field {
                        margin-bottom: 0;
                    }

                    select {
                        display: block;
                        width: 100%;
                        padding: 0.375rem 2.25rem 0.375rem 0.75rem;
                        font-size: 1.5rem;
                        height: 40px;
                        font-weight: 400;
                        line-height: 1.5;
                        color: $dark;
                        border: 1px solid #E2E2E2;
                        border-radius: $border-radius-sm;
                    }
                }
            }

            .box-tocart {
                width: 100%;
                order: 2;

                .fieldset {
                    @include media-breakpoint-between(xs, md) {
                        display: flex;
                    }

                    .field {
                        @include media-breakpoint-between(xs, md) {
                            margin-bottom: 0;
                        }
                    }
                }

                .control {
                    display: flex;
                    border: 1px solid #E2E2E2;
                    border-radius: $border-radius-sm;
                    overflow: hidden;

                    &:hover {
                        border-color: lighten($primary, 50);
                    }

                    button {
                        background-color: transparent;
                        border: 0;
                        color: #adadba;

                        &:hover {
                            background-color: lighten($primary, 60);
                            color: $primary;
                        }

                        &:active {
                            box-shadow: none;
                        }
                    }

                    .qty {
                        height: 50px;
                        border: 0;

                        @include media-breakpoint-between(xs, md) {
                            padding: 0;
                            width: 35px;
                        }

                        &:focus {
                            box-shadow: none;
                            outline: 0;
                        }
                    }
                }

                .actions {
                    padding: 0;
                    width: 100%;

                    .tocart {
                        border-radius: $border-radius-sm;
                        width: 100%;
                        text-transform: lowercase;
                        background-color: #ffd814;
                        border: #ffd814;

                        &:hover {
                            background-color: #e7c415;
                            border-color: #e7c415;
                        }

                        @include media-breakpoint-between(xs, md) {
                            font-size: 1.6rem;
                        }

                        &::first-letter {
                            text-transform: capitalize;
                        }
                    }
                }
            }

            .product-info-stock-sku {
                order: 1;

                @include media-breakpoint-up(xs) {
                    margin-top: 1.5rem;
                }

                @include media-breakpoint-up(md) {
                    margin-top: 2rem;
                }

                .stock {
                    text-transform: none;
                    font-size: 1.4rem;
                    font-weight: $font-weight-normal;
                    display: inline-flex;
                    align-items: center;
                    display: inline-block;
                    margin-left: 0.5rem;

                    &:after {
                        content: "";
                        width: 5px;
                        height: 5px;
                        border-radius: 100%;
                    }

                    &.available {
                        color: $button-primary__background;

                        &:after {
                            background-color: $button-primary__background;
                        }
                    }
                }
            }
        }

        .quotation-form {
            order: 4;
            margin-bottom: 1.5rem;

            .btn-quotation {
                display: block;
                width: 100%;
                padding: 14px 17px;
                font-size: 1.6rem;
                background-color: #ffa41c;
                border-color: #ffa41c;

                &:hover {
                    background-color: #de8b0f;
                    border-color: #de8b0f;
                }
            }
        }

        #quotationModal {
            .modal-header {
                background-color: $primary;
                border-radius: 0;

                .btn-close {
                    background-color: #fff;
                    border-radius: 100%;
                    opacity: 1;
                    font-size: 12px;
                    padding: 1rem;
                }

                .modal-title {
                    color: #fff;
                }
            }

            .modal-body {
                padding: 0;

                .form-group {
                    margin-right: 0;
                    padding: 0;

                    @include media-breakpoint-up(sm) {
                        display: flex;
                    }

                    label {
                        margin: 0;
                        min-width: 30%;
                        display: flex;
                        align-items: center;
                    }

                    .control {
                        width: 100%;
                    }

                    &.field-productname,
                    &.field-productsku,
                    &.field-productvariant {
                        display: none;
                    }
                }

                .product-details {
                    padding: 1rem;
                }

                .amform-form {
                    padding: 2rem;
                    background-color: $light;
                }

                .amcform-toolbar {
                    display: flex;
                    justify-content: flex-end;
                }

                #productImage {
                    margin: 0;
                    max-width: 100px;
                }
            }
        }

        .product-meta {
            order: 5;

            .usps {
                li {
                    @include media-breakpoint-between(xs, md) {
                        font-size: 1.4rem;
                    }
                }
            }
        }

        .timer {
            order: 4;
            color: #007600 !important;

            p {
                line-height: 1.2;

                strong {
                    color: #007600 !important;
                }
            }
        }

        .kiyoh-widget-plain {
            order: 6;
            padding-left: 15px;
            display: flex;
            align-items: center;
            position: relative;

            &:before {
                content: "";
                @extend .svg-icon;
                @extend .svg-icon__check;
                margin-right: 1rem;
            }

            .rating-summary {
                display: none;
            }

            div {
                span {
                    display: none;
                }

                b {
                    &:before {
                        content: "Klanten beoordelen ons met een";
                        display: inline-block;
                        margin-right: 5px;
                        font-weight: $font-weight-normal;
                    }

                    &:last-child {
                        display: none;
                    }
                }
            }

            a {
                display: none;
            }
        }
    }

    .product.media {
        @include media-breakpoint-between(xs, md) {
            height: 200px !important;
            margin: 0 -15px;
        }

        .gallery-placeholder {
            position: relative;

            &._block-content-loading {
                visibility: hidden;
            }
        }

        .badge {
            top: 15px;
            z-index: 1;

            @include media-breakpoint-up(xs) {
                left: 15px;
            }

            @include media-breakpoint-up(md) {
                left: 135px;
            }
        }

        .fotorama__stage__shaft {
            background-color: $white;
            width: 100% !important;
            max-width: 100% !important;

            .fotorama__stage__frame {
                background-color: $white;
            }
        }

        .fotorama__wrap {
            @include media-breakpoint-up(md) {
                padding-left: 120px;
            }

            .fotorama__stage {
                border-radius: $border-radius;
                left: 0 !important;

                @include media-breakpoint-between(xs, md) {
                    height: 200px !important;
                }
            }

            .fotorama__arr {
                transform: unset !important;
                opacity: 1;

                @include media-breakpoint-up(xs) {
                    width: 50px;
                }

                @include media-breakpoint-up(md) {
                    width: 80px;
                }

                .fotorama__arr__arr {
                    background-color: $black;
                    mask-size: contain;
                    mask-repeat: no-repeat;

                    @include media-breakpoint-up(xs) {
                        width: 8px;
                        height: 16px;
                    }

                    @include media-breakpoint-up(md) {
                        width: 12px;
                        height: 20px;
                    }
                }

                &--prev {
                    .fotorama__arr__arr {
                        mask-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.2.3, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='276.5px' height='512px' viewBox='0 0 276.5 512' style='enable-background:new 0 0 276.5 512;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M5.8,270.1l236.8,236.2c7.8,7.7,20.3,7.7,28.1,0c7.7-7.8,7.7-20.3,0-28.1L48,256L270.7,33.9c7.8-7.7,7.8-20.3,0-28.1 c-3.9-3.9-9-5.8-14.1-5.8c-5.1,0-10.1,1.9-14,5.8L5.8,242c-3.7,3.7-5.8,8.8-5.8,14S2.1,266.3,5.8,270.1z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
                    }
                }

                &--next {
                    .fotorama__arr__arr {
                        mask-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.2.3, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='276.5px' height='512px' viewBox='0 0 276.5 512' style='enable-background:new 0 0 276.5 512;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M270.7,242L33.9,5.8c-7.8-7.7-20.3-7.7-28.1,0c-7.7,7.8-7.7,20.3,0,28.1L228.6,256L5.8,478.1c-7.8,7.7-7.8,20.3,0,28.1 c3.9,3.9,9,5.8,14.1,5.8c5.1,0,10.1-1.9,14-5.8l236.8-236.2c3.7-3.7,5.8-8.8,5.8-14S274.4,245.7,270.7,242z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
                    }
                }

                &.fotorama__arr--next {
                    right: 0 !important;
                }
            }
        }

        .fotorama__nav-wrap {
            .fotorama__nav {
                .fotorama__shadows--top {
                    &:before {
                        opacity: 0.4;
                    }
                }

                .fotorama__thumb__arr {
                    &.fotorama__thumb__arr--left {
                        .fotorama__thumb--icon {
                            margin-top: 10px;
                        }
                    }

                    &.fotorama__thumb__arr--right {
                        .fotorama__thumb--icon {
                            transform: rotate(-90deg);
                        }
                    }

                    .fotorama__thumb--icon {
                        background-color: $black;
                        width: 12px;
                        height: 15px;
                        mask-size: contain;
                        mask-repeat: no-repeat;
                        mask-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.2.3, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='276.5px' height='512px' viewBox='0 0 276.5 512' style='enable-background:new 0 0 276.5 512;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M5.8,270.1l236.8,236.2c7.8,7.7,20.3,7.7,28.1,0c7.7-7.8,7.7-20.3,0-28.1L48,256L270.7,33.9c7.8-7.7,7.8-20.3,0-28.1 c-3.9-3.9-9-5.8-14.1-5.8c-5.1,0-10.1,1.9-14,5.8L5.8,242c-3.7,3.7-5.8,8.8-5.8,14S2.1,266.3,5.8,270.1z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
                        padding: 0;
                    }
                }

                .fotorama__active {
                    .fotorama__thumb {
                        border: 1px solid $primary;
                        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .1);
                    }

                    .fotorama__dot {
                        background-color: $dark;
                        border-color: $dark;
                    }
                }

                .fotorama__thumb {
                    border-radius: $border-radius;
                    border: 1px solid $light;
                    box-shadow: $box-shadow-sm;
                }

                .fotorama__img {
                    padding: 1rem;
                    background-color: $white;
                }
            }

            .fotorama__nav--dots {
                .fotorama__nav__frame {
                    width: 15px;
                }
            }
        }

        .fotorama__nav__shaft {
            background-color: transparent;

            @include media-breakpoint-up(xs) {
                margin-top: -10px;
            }

            .fotorama__dot {
                width: 6px;
                height: 6px;
            }

            .fotorama__thumb-border {
                display: none;
            }

            .fotorama__nav__frame {
                margin: 10px 0;
            }
        }
    }
}

.product.info.detailed {
    @include media-breakpoint-between(xs, md) {
        margin-bottom: 0 !important;
    }

    .navigation-bar {
        background-color: #f5f5f5;

        a {
            @include media-breakpoint-between(xs, md) {
                font-size: 1.3rem;
            }

            &:hover {
                text-decoration: none;
                background-color: transparent !important;
            }
        }

        @include media-breakpoint-between(xs, md) {
            margin: 0 -15px;
            padding-left: 15px;
            padding-right: 15px;
        }

        &:before,
        &:after {
            content: "";
            background-color: #f5f5f5;
            position: absolute;
            bottom: 0;
            top: 0;
            z-index: -1;
            width: calc(((100vw - 1410px) / 2));
        }

        &:before {
            right: 100%;
        }

        &:after {
            left: 100%;
        }
    }

    .card-service {
        &.sticky-top {
            top: 18rem;
        }

        img {
            max-height: 260px;
        }
    }

    #specifications {
        h2 {
            @include media-breakpoint-up(xs) {
                font-size: 2.2rem;
            }

            @include media-breakpoint-up(md) {
                font-size: 2.5rem;
            }
        }

        h3 {
            @include media-breakpoint-up(xs) {
                font-size: 1.6rem;
            }

            @include media-breakpoint-up(md) {
                font-size: 1.8rem;
            }
        }

        .additional-attributes-wrapper {
            @include media-breakpoint-between(xs, md) {
                margin: 0 -15px;
                width: calc(100% + 30px);
            }
        }

        table {
            tr {
                td {
                    padding: 0.8rem 1.5rem;
                    font-family: $font-family-name__base;

                    @include media-breakpoint-up(xs) {
                        font-size: 1.2rem;
                    }

                    @include media-breakpoint-up(md) {
                        font-size: 1.3rem;
                    }

                    &:before {
                        font-weight: 600;
                        color: $dark;
                    }

                    &.label {
                        font-weight: 600;

                        @include media-breakpoint-between(xs, sm) {
                            display: none;
                        }

                        @include media-breakpoint-up(sm) {
                            width: 50%;
                        }

                        @include media-breakpoint-up(lg) {
                            width: 33.333%;
                        }
                    }
                }
            }
        }
    }

    #description {
        .description {
            padding-top: 3rem;
            margin-top: 3rem;
            border-top: 1px solid #dee2e6;
        }
    }

    .am-attachments {
        display: flex;
        flex-wrap: wrap;
        margin-top: 3rem;
        padding-top: 3rem;
        border-top: 1px solid #dee2e6;


        &:before {
            content: "Downloads";
            flex: 100%;
            font-size: 1.8rem;
            font-weight: 600;
            margin-bottom: 0.5rem;
        }

        .am-fileline {
            @extend .btn;
            @extend .btn-light;
            margin-right: 1rem;
            position: relative;
            display: flex;
            align-items: center;
            border: 1px solid #eee;
            margin-bottom: 1rem;

            &:hover {
                .am-filelink {
                    text-decoration: none;
                }
            }

            .am-fileicon {
                width: 20px;
                margin-right: 1rem;
                filter: brightness(0) invert(0.3);
            }

            .am-filelink {
                padding: 1rem 0;
                color: $dark;

                &:after {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: 1;
                    content: "";
                }
            }

            .am-filesize {
                margin-left: 1rem;
                color: #acacac;
            }
        }
    }
}

.related,
.upsell {
    @include media-breakpoint-between(xs, sm) {
        margin-bottom: 1.5rem;
    }

    h2 {
        @include media-breakpoint-between(xs, sm) {
            font-size: 2rem;
        }
    }

    .products-grid {
        .product-items {
            margin-left: 0;
            margin-right: 0;

            @include media-breakpoint-between(xs, sm) {
                overflow-x: scroll;
                flex-wrap: nowrap;
            }

            .product-item {
                @include media-breakpoint-between(xs, sm) {
                    min-width: 80vw;
                    margin-right: 1rem;
                    padding-right: 1rem;
                    border-right: 1px solid #e8ecf0;
                }

                @include media-breakpoint-up(xs) {
                    width: 100%;
                }

                @include media-breakpoint-up(md) {
                    width: 25%;
                }

                .product-item-info {
                    position: relative;

                    @include media-breakpoint-between(xs, sm) {
                        border: 0;
                    }
                }
            }
        }
    }
}

.modal-add-to-cart {
    .actions {
        .btn-success {
            background-color: #ffd814;
            border-color: #ffd814;
            color: #333 !important;

            &:hover {
                background-color: #e7c415;
                border-color: #e7c415;
                text-decoration: none;
            }
        }
    }
}
