html,
body {
    height: auto;
}

/* Hero image
========================================= */
.row-type-hero-image {
    position: relative;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
    }

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

/* Service blocks
========================================= */
.row-type-service-blocks {
    .row-type-service-blocks__inner {
        @include media-breakpoint-up(md) {
            margin-top: -10rem;
        }
        @include media-breakpoint-up(lg) {
            margin-top: -15rem;
        }

        h1 {
            font-weight: 600;
            line-height: 1;
        }

        .service-item {
            transition: .3s ease;

            &:hover {
                transform: translateY(-5px);
            }
        }

        .quick-links {
            img {
                width: 100%;
                height: 80px;
                object-fit: cover;
            }
        }

        .btn-link {
            color: $primary;
            font-size: 1.3rem;
            display: block;

            i {
                background-color: $primary;
            }
        }
    }
}

/* FAQ search
========================================= */
.row-type-faq-search {
    @include media-breakpoint-up(xs) {
        margin-top: 2.5rem;
    }

    @include media-breakpoint-up(md) {
        margin-top: 5rem;
    }

    h2 {
        font-weight: 600;
    }

    .am-search-box-wrapper {
        .am-widget-center {
            margin: 0 auto;

            @include media-breakpoint-down(md) {
                width: 100% !important;
            }
        }
    }
}

.amfaq-search {
    margin: 0;
    position: relative;

    .am-input {
        border: 1px solid #ced4da;
        border-radius: .5rem;
        box-shadow: 0 1px 3px 0 rgba(96, 96, 96, 0.2);
        height: calc(3em + .75rem + 2px);
        cursor: text;

        &:focus {
            outline: 0;
        }
    }

    .am-button {
        top: 10px;
        right: 10px;
        position: absolute;
        padding: 0;
        width: 30px;
        height: 32px;
        border: 0;
        box-shadow: none;
        background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1Ni45NjYgNTYuOTY2IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiI+PHBhdGggZD0iTTU1LjE0NiA1MS44ODdMNDEuNTg4IDM3Ljc4NkEyMi45MjYgMjIuOTI2IDAgMCAwIDQ2Ljk4NCAyM2MwLTEyLjY4Mi0xMC4zMTgtMjMtMjMtMjNzLTIzIDEwLjMxOC0yMyAyMyAxMC4zMTggMjMgMjMgMjNjNC43NjEgMCA5LjI5OC0xLjQzNiAxMy4xNzctNC4xNjJsMTMuNjYxIDE0LjIwOGMuNTcxLjU5MyAxLjMzOS45MiAyLjE2Mi45Mi43NzkgMCAxLjUxOC0uMjk3IDIuMDc5LS44MzdhMy4wMDQgMy4wMDQgMCAwIDAgLjA4My00LjI0MnpNMjMuOTg0IDZjOS4zNzQgMCAxNyA3LjYyNiAxNyAxN3MtNy42MjYgMTctMTcgMTctMTctNy42MjYtMTctMTcgNy42MjYtMTcgMTctMTd6IiBmaWxsPSIjYmFiYWJhIi8+PC9zdmc+);
        background-position-x: 0%;
        background-position-y: 0%;
        background-repeat: repeat;
        background-size: auto;
        background-size: 20px;
        background-position: center;
        background-repeat: no-repeat;
    }

    .search-autocomplete {
        margin-top: -5px;
        border-radius: 0 0 .5rem .5rem;
        border-left: 1px solid #ced4da;
        border-right: 1px solid #ced4da;
        border-bottom: 1px solid #ced4da;
        box-shadow: 0 2rem 3rem rgba(0, 0, 0, 0.175);

        ul {
            li {
                &:not(:empty) {
                    border: 0;
                    border-bottom: 1px solid #f2f2f2;
                }

                &:last-child {
                    border: 0;
                }

                .amfaq-category {
                    display: none;
                }
            }
        }
    }
}

.amfaq_categories {
    margin-top: 1rem;

    .title {
        margin-bottom: .5rem;
    }
}

.faq-search-index {
    .page-title-wrapper {
        margin-top: 0;
    }
}

/* FAQ categories
========================================= */
.row-type-faq-categories {
    .am-widget-categories-3 {
        margin: 0 -15px;
        display: block;

        @include media-breakpoint-up(md) {
            column-count: 2;
            column-gap: .2rem;
        }

        @include media-breakpoint-up(lg) {
            column-count: 3;
            column-gap: .2rem;
        }

        .am-widget-category {
            padding: 0 15px;
            width: 100%;
            break-inside: avoid;

            .amfaq_questions {
                display: flex;
                flex-flow: column;
            }

            .am-category-container {
                background-color: #fff;
                border-radius: $border-radius-lg;
                box-shadow: 0 1px 3px 0 rgba(96, 96, 96, 0.2);
                border: 1px solid #e3e5e8;

                @include media-breakpoint-up(xs) {
                    margin-bottom: 20px;
                    padding: 1.5rem;
                }

                @include media-breakpoint-up(md) {
                    margin-bottom: 30px;
                    padding: 2rem;
                }

                .am-category-header {
                    min-height: unset;
                }


                .view-all {
                    color: $secondary;
                    font-size: 1.3rem;
                }
            }
        }
    }
}

/* FAQ view
========================================= */
.faq-category-view,
.faq-question-view {
    .columns {
        .sidebar {
            @include media-breakpoint-down(md) {
                margin-bottom: 2rem;
            }

            @include media-breakpoint-up(md) {
                width: 20%;
            }

            .amfaq-search {
                display: none;
            }

            .amfaq_categories {
                background-color: $white;
                border: 0;
                overflow: hidden;
                border-radius: 0;

                .title {
                    display: none;
                }

                .item {
                    font-weight: 500;
                    padding: 0;
                    border-left: 3px solid transparent;

                    &:last-child {
                        border-radius: 0;
                    }

                    &.current,
                    &:hover {
                        border-left: 3px solid $secondary;
                        background-color: transparent;
                    }

                    a {
                        display: block;
                        color: #000;
                        padding: 1rem;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        .column {
            @include media-breakpoint-up(md) {
                width: 80%;
            }

            .page-title-wrapper {
                margin-bottom: 2.5rem;
            }

            .ask-question-form {
                display: none;
            }

            .am-back-button {
                display: none;
            }

            .amfaq_questions {
                .am-no-items {
                    background-color: $info;
                    color: #fff;
                    padding: 1rem 1.5rem;
                }

                .am-item {
                    background-color: $white;
                    border: 1px solid #f7f7f7;
                    border: 0;
                    margin-bottom: 0.5rem;
                    padding: 0;
                    cursor: pointer;

                    .am-title {
                        margin: 0;
                        display: flex;
                        align-items: center;

                        @include media-breakpoint-up(xs) {
                            padding: .75rem;
                        }

                        @include media-breakpoint-up(md) {
                            padding: 1rem;
                        }

                        &:after {
                            content: "";
                            mask-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.2.3, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='276.5px' height='512px' viewBox='0 0 276.5 512' style='enable-background:new 0 0 276.5 512;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M270.7,242L33.9,5.8c-7.8-7.7-20.3-7.7-28.1,0c-7.7,7.8-7.7,20.3,0,28.1L228.6,256L5.8,478.1c-7.8,7.7-7.8,20.3,0,28.1 c3.9,3.9,9,5.8,14.1,5.8c5.1,0,10.1-1.9,14-5.8l236.8-236.2c3.7-3.7,5.8-8.8,5.8-14S274.4,245.7,270.7,242z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
                            width: 8px;
                            height: 14px;
                            mask-size: contain;
                            mask-repeat: no-repeat;
                            display: inline-block;
                            background-color: $body-color;
                            margin-left: auto;
                            transition: 0.3s ease;
                        }

                        &[aria-expanded="true"] {
                            &:after {
                                transform: rotate(90deg);
                            }
                        }
                    }
                }
            }

            .question_view {
                border: 0;
                margin: 0;
                padding: 0;
            }
        }
    }
}

/* FAQ
========================================= */
.amfaq_questions {
    .am-item {
        border: 0;
        margin: 0 0 .75rem 0;

        @include media-breakpoint-up(xs) {
            padding: .75rem;
        }

        @include media-breakpoint-up(md) {
            padding: 1rem;
        }

        &:nth-child(odd) {
            background: #f7f7f7;
        }

        .am-title {
            margin-bottom: 0;
            font-size: 1.5rem;
            line-height: 1.6;
            font-weight: 500;

            .am-minus,
            .am-plus {
                &:before {
                    font-size: 3.5rem;
                    color: #000;
                }
            }
        }

        .am-content {
            padding: 1rem;
            line-height: 1.7;

            .amfaq-question-footer-wrapper {
                margin-top: 1rem;
                justify-content: flex-end;

                .am-links {
                    padding: 0;

                    a {
                        color: $secondary;
                        font-size: 1.3rem;
                    }
                }
            }
        }
    }
}

/* Service block
========================================= */
.row-type-service-block {
    .card-customer-service {
        .card-subtitle {
            color: #421d0b;
        }

        .team {
            @include media-breakpoint-down(xs) {
                left: -11rem;
                position: relative;
                max-height: 250px;
            }

            @include media-breakpoint-up(xs) {
                max-width: 200px;
                margin-top: 4rem;
                object-fit: contain;
                object-position: right bottom;
            }

            @include media-breakpoint-up(md) {
                max-width: 360px;
                margin-right: -4rem;
                margin-top: 2rem;
                object-fit: cover;
                object-position: left;
            }
        }

        .btn-group {
            .btn {
                @include media-breakpoint-down(sm) {
                    display: flex;
                    border-radius: .5rem;
                    background: #424242;
                    border-color: #424242;
                }
            }
        }
    }
}

/* Row categories
========================================= */
.row-type-categories {
    .card {
        .card-title {
            @include media-breakpoint-between(xs, md) {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        &:hover {
            background-color: $primary;

            img {
                mix-blend-mode: multiply;
            }

            .card-title {
                a {
                    color: #fff !important;
                    text-decoration: none;
                }
            }
        }
    }
}
