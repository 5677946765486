.catalogsearch-result-index {
    &.no-results {
        .page-title-wrapper {
            display: none;
        }

        .sidebar {
            display: none;
        }

        .columns {
            .column {
                &.main {
                    width: 100% !important;

                    .card {
                        @include media-breakpoint-up(md) {
                            max-width: 75%;
                            margin: 0 auto;
                        }

                        @include media-breakpoint-up(lg) {
                            max-width: 50%;
                        }

                        .card-img-top {
                            object-fit: cover;

                            @include media-breakpoint-up(xs) {
                                height: 100px;
                            }

                            @include media-breakpoint-up(md) {
                                height: 175px;
                            }
                        }

                        .block-search {
                            max-width: 100%;

                            .control {
                                background-color: transparent;
                                border: 0;

                                .input-text {
                                    border: 1px solid #e3e3e3;
                                }
                            }
                        }

                        .card-footer {
                            .svg-icon {
                                background-color: $primary;
                            }
                        }
                    }
                }
            }
        }
    }
}
