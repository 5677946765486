.whatsapp-icon {
    position: fixed;
    height: 48px;
    width: 48px;
    right: $container-padding-x;
    bottom: $container-padding-x;
    transition: $transition-base;
    z-index: 1039;
    background-color: #00D856;

    &__badge {
        width: 20px;
        height: 20px;
        right: -2px;
        top: -4.8px;
        font-family: Helvetica, sans-serif;
        font-weight: 600;
        background-color: #f72539;
        display: none;
        color: $white !important;
    }

    &:after {
        content: '';
        position: absolute;
        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 24 24'%3E%3Cpath d='M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z'/%3E%3C/svg%3E");
        mask-repeat: no-repeat;
        mask-size: contain;
        mask-position: center;
        background-color: #FFF;
        width: 25px;
        height: 25px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &:hover {
        cursor: pointer;
        transform: scale(1.1);
    }
}

.whatsapp-popup {
    display: none;
    position: fixed;
    right: calc(#{$container-padding-x} - 1px);
    bottom: calc(#{$container-padding-x} - 1px);
    max-width: 320px;
    width: calc(100% - #{$grid-gutter-width});
    z-index: 1039;

    @include media-breakpoint-down(md) {
        max-width: calc(100% - (#{$grid-gutter-width} * 2));
    }

    .card {
        border-radius: 8px !important;
    }

    .card-header {
        background-color: #10887a;
        border-radius: 8px 8px 0 0;
        padding: 10px;
        position: relative;

        &:after {
            content: "";
            background-color: rgba(#FFF, .14);
            border-bottom: 1px solid #d9d9d9;
            position: absolute;
            bottom: -1px;
            width: 100%;
            height: 1px;
            left: 0;
        }

        .profile-picture {
            width: 45px;
            height: 45px;
            background-color: #FFF;
            margin-right: 10px;

            img {
                object-fit: contain;
                padding: 10px;
            }
        }

        .whatsapp-profile{
            max-width: calc(100% - 55px - 25px);

            &__name {
                font-family: Tahoma, sans-serif;
                font-size: 14px;
                font-weight: 600;
                color: #FFF;
            }
            &__status {
                font-family: Tahoma, sans-serif;
                line-height: 1.2;
                font-size: 12px;
                font-style: italic;
                font-weight: 400;
                color: #FFF;
                opacity: .75;
            }
        }

        .close-whatsapp {
            right: 8px;
            top: 8px;
            width: 20px;
            height: 20px;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            &:after {
                content: '';
                mask-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='times' class='svg-inline--fa fa-times fa-w-11' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 352 512'%3E%3Cpath fill='%23FFF' d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z'%3E%3C/path%3E%3C/svg%3E");
                mask-repeat: no-repeat;
                mask-size: contain;
                mask-position: center;
                background-color: #FFF;
                opacity: .75;
                position: absolute;
                width: 15px;
                height: 15px;
                transition: $transition-base;
            }

            &:hover {
                cursor: pointer;

                &:after {
                    opacity: 1;
                }
            }
        }

    }

    .card-body {
        background-color: #F9FAFB;
        background-image: url('../Media101_WhatsAppChat/images/whatsapp-background.png');
        background-position: center;
        background-size: 100%;
        min-height: 160px;
        padding: 10px 10px 10px 22px;

        .whatsapp-message {
            background-color: #FFFFFF;
            border-radius: 0 8px 8px 8px;
            line-height: 1.3;
            font-family: Tahoma, sans-serif;
            font-weight: 400;
            box-shadow: 0 1px 0.5px rgba(#000,.13);
            padding: 10px 12px;
            font-size: 13.6px;

            &:before {
                content: '';
                display: block;
                clip-path: polygon(100% 100%, 0 0, 100% 0);
                height: 12px;
                width: 12px;
                background-color: inherit;
                position: absolute;
                right: calc(100% - 1px);
                top: 0;
            }

            &:after {
                content: "";
                clear: both;
                display: table;
            }

            &__details {
                float: right;
                margin-right: -4px;

                .time-indication {
                    font-size: 10px;
                    color: #5A5A5E;
                }

            }
        }
    }

    .card-footer {
        background-color: #EDEDED;
        padding: 10px;
        border-radius: 0 0 8px 8px;
        position: relative;

        &:after {
            content: "";
            background-color: rgba(#FFF, .14);
            border-bottom: 1px solid #d9d9d9;
            position: absolute;
            top: -1px;
            width: 100%;
            height: 1px;
            left: 0;
        }

        .btn.btn-primary {
            background-color: #00D856;
            border-color: #00D856;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 100px;
            font-family: Tahoma, sans-serif;
            font-weight: 600;
            margin: 0 auto;
            padding: 8px 20px;
            font-size: 13px;
            color: #fff;

            &:hover {
                background-color: darken(#00D856, 5%);
                border-color: darken(#00D856, 5%);
                text-decoration: none;
            }

            &:before {
                content: '';
                mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 24 24'%3E%3Cpath d='M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z'/%3E%3C/svg%3E");
                mask-repeat: no-repeat;
                mask-size: contain;
                mask-position: center;
                background-color: #FFF;
                display: inline-block;
                width: 14px;
                height: 14px;
                margin-right: 10px;
                flex-shrink: 0;
            }
        }
    }
}
