// Layout
$layout__max-width                  : 1450px;
$layout-column__sidebar-width       : 2.5;
$indent__base                       : 1.5rem;

// Mini cart
$minicart__padding-horizontal       : 0;

// Navigation
$navigation__background             : transparent;

// Breadcrumbs
$breadcrumbs__container-margin      : 0 0 1rem;
$breadcrumbs__padding               : 0;

// Modals
$modal__box-shadow                  : 0 0 22px 0px rgba(0, 0, 0, 0.25);
$modal-popup__width                 : 50%;

//
//  Buttons
//  ---------------------------------------------

// Font style
$button__font-family                : 'neuzeit-grotesk', sans-serif;
$button__font-weight                : 500;

// Primary button
$button-primary__background         : #12ac74;
$button-primary__border             : 1px solid $button-primary__background;

$button-primary__hover__background  : #4cc79b;
$button-primary__hover__border      : 1px solid $button-primary__hover__background;

$button-primary__active__background : $button-primary__hover__background;
$button-primary__active__border     : 1px solid $button-primary__hover__background;
