a.pagebuilder-banner-button,
button.pagebuilder-banner-button {
    border: 0;
    padding: 1rem 1.5rem;
    font-size: 1.5rem;
    margin: 0 !important;

    &:hover,
    &:focus,
    &.active,
    &:active {
        border: 0;
    }
}

a.pagebuilder-button-primary,
button.pagebuilder-button-primary {
    background-color: $primary;
    color: #fff;
}

a.pagebuilder-button-secondary,
button.pagebuilder-button-secondary {
    background-color: $secondary;
    color: #fff;
}


[data-content-type="buttons"] {
    [data-element="link"] {
        border: 0;
        padding: 1rem 1.5rem;
        font-size: 1.5rem;
        margin: 0 !important;

        &:hover,
        &:focus,
        &.active,
        &:active {
            border: 0;
        }
    }
}
