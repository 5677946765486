form {
    &.form {
        &.form-create-account {
             .field {
                 &.choice {
                     display: none;
                 }
             }   
        }

        .fieldset {
            margin-bottom: 0;
        
            &:after {
                display: none;
            }
        
            .field {
                margin-bottom: 1.5rem;

                &._required,
                &.required {
                    label {
                        &.label {
                            &:after {
                                top: 5px;
                                margin: 0 0 0 5px;
                            }
                        }
                    }
                }

                &.choice {
                    &:before {
                        display: none;
                    }
                }
                
                label {
                    &.label {
                        font-family: $font-family__base;
                        float: none;
                        width: 100%;
                        text-align: left;
                        padding: 0;
                        font-size: 1.3rem;
                        color: #555;   
                        font-weight: 500;
                        margin-bottom: 0.5rem;  
                    }                  
                }
        
                .control {
                    float: none;
                    width: 100%;
        
                    input {
                        height: 5.2rem;
                        border: 1px solid #d8d8d8;
                        border-radius: .2rem;
                        padding: 0 1.6rem;      
                        
                        &:focus {
                            box-shadow: none;
                        }
                    }
                }
            }
        }
        .actions-toolbar {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-left: 0 !important;
    
            &:before,
            &:after {
                display: none;
            }
    
            .remind,
            .back {
                color: #000;
                font-size: 1.2rem;
                margin: 0;
    
                &:hover {
                    text-decoration: underline;
                }
            }
        }        
    }
}