
    .products {
        margin-top: 0;
    }

    .products-grid,
    .products-list {
        width: auto;

        .product-items {
            width: auto;
            display: flex;
            flex-wrap: wrap;
            padding: 0;

            .product-item {
                .product-item-info {
                    position: relative;

                    @include media-breakpoint-between(xs,md) {
                        border-bottom: 1px solid #e8ecf0;
                    }
                }

                &:first-child {
                    .product-item-info {
                        @include media-breakpoint-between(xs,md) {
                            border-top: 1px solid #e8ecf0;
                        }
                    }
                }

                &-info {
                    display: flex;
                    background-color: #fff;
                    width: 100%;
                    overflow: hidden;
                    padding: 1.5rem;
                    height: 100%;
                    transition: $transition-base;

                    @include media-breakpoint-between(xs,md) {
                        align-items: center;
                    }

                    @include media-breakpoint-up(md) {
                        box-shadow: $box-shadow-lg;
                        border-radius: $border-radius;
                        border: 1px solid #e8ecf0;
                    }

                    &:hover {
                        box-shadow: 0 0.375rem 1.5rem 0 rgba(140, 152, 164, 0.325);
                    }

                    .badge {
                        top: 1.5rem;
                        z-index: 2;
                    }

                    .product-item-photo {
                        display: block;

                        @include media-breakpoint-between(xs,md) {
                            width: 30%;
                            margin-right: 5%;
                        }

                        .product-image-container {
                            width: 100% !important;
                            aspect-ratio: unset !important;

                            .product-image-wrapper {
                                padding-bottom: 0 !important;
                                height: auto;

                                .product-image-photo {
                                    width: auto;
                                    position: relative;

                                    @include media-breakpoint-up(md) {
                                        height: 180px;
                                    }
                                }
                            }
                        }
                    }

                    .product-item-details {
                        display: flex;
                        flex-direction: column;

                        @include media-breakpoint-between(xs,md) {
                            width: 70%;
                        }

                        @include media-breakpoint-up(md) {
                            flex-grow: 1;
                        }

                        .amshopby-option-link {
                            position: absolute;
                            margin: 0;
                            z-index: 1;

                            @include media-breakpoint-between(xs,md) {
                                bottom: 15px;
                                left: 15px;
                            }

                            @include media-breakpoint-up(md) {
                                top: 15px;
                                right: 15px;
                            }

                            img {
                                width: 70px;
                                max-height: 50px;
                                object-fit: contain;
                                object-position: right;
                                margin: 0;
                            }

                            ~ .amshopby-option-link {
                                right: 80px;
                            }
                        }
                    }

                    .product-item-name {
                        line-height: 1.2;
                        font-weight: $font-weight-normal;

                        @include media-breakpoint-between(xs,md) {
                            margin: 0;
                            font-size: 1.5rem;
                        }
                    }

                    ul.list {
                        font-size: 1.5rem;
                        flex-grow: 1;
                        list-style-type: disc;
                        margin: 0.5rem 0;
                        line-height: 1.3;

                        li {
                            margin: 0;
                        }
                    }

                    .product-item-inner {
                        @include media-breakpoint-between(xs,md) {
                            border-top: 0 !important;
                        }
                    }

                    .price-box {
                        line-height: 1;
                        flex: 1;
                        display: flex;
                        align-items: flex-end;
                        margin-top: 0;

                        @include media-breakpoint-between(xs,md) {
                            margin: 0;
                        }

                        .minimal-price-link {
                            .price {
                                font-weight: $font-weight-bold;
                            }
                        }

                        .price-label {
                            color: #000;
                            font-weight: $font-weight-bold;
                        }

                        .special-price {
                            line-height: 0;

                            .price-wrapper {
                                line-height: 1;
                            }
                        }

                        .price-including-tax {
                            .price {
                                color: #000;

                                @include media-breakpoint-up(xs) {
                                    font-size: 2rem;
                                }

                                @include media-breakpoint-up(md) {
                                    font-size: 2.5rem;
                                }
                            }
                        }

                        .price-excluding-tax {
                            .price {
                                font-weight: 500;
                            }
                        }

                        .old-price {
                            line-height: 1;
                            color: $dark;
                            overflow: hidden;

                            .price-final_price {
                                line-height: 1;

                                .price-wrapper {
                                    line-height: 1;
                                }
                            }

                            .price {
                                font-size: 1.5rem;
                                color: $dark;
                            }
                        }
                    }

                    .product-item-actions {
                        display: block;

                        .stock {
                            &.available {
                                i {
                                    margin-right: 0.5rem;
                                }
                            }

                            i {
                                width: 15px;
                                height: 10px;
                            }
                        }

                        .tocart {
                            max-height: 55px;

                            @include media-breakpoint-up(xs) {
                                padding: 1.4rem;
                            }

                            @include media-breakpoint-up(md) {
                                padding: 1.8rem;
                            }

                            i {
                                background-color: #fff !important;
                                width: 20px;
                                height: 17px;
                            }
                        }
                    }
                }
            }
        }
    }

    .products-grid {
        .product-items {
            margin: 0 -1.5rem;

            .product-item {
                margin: 0;

                @include media-breakpoint-up(xs) {
                    width: 100%;
                    padding: 0;
                }

                @include media-breakpoint-up(md) {
                    width: 50%;
                    padding: 0 15px;
                    margin-bottom: 3rem;
                }

                @include media-breakpoint-up(lg) {
                    width: 33.333%;
                }

                &-info {
                    @include media-breakpoint-up(md) {
                        flex-direction: column;
                    }
                }
            }
        }

        &.products-4 {
            .product-items {
                .product-item {
                    @include media-breakpoint-up(lg) {
                        width: 25%;
                    }
                }
            }
        }
    }

    .products-list {
        .product-items {
            .product-item {
                margin-bottom: 1.5rem;

                &-info {
                    align-items: flex-end;

                    .badge {
                        top: 1.5rem;
                    }

                    .product-item-photo {
                        width: 25%;
                        padding: 0;
                    }

                    .product-item-description {
                        display: none;
                    }
                }
            }
        }
    }
