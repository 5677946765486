.sidebar-main {
    .account-nav {
        .content {
            background-color: $white;
            border-radius: $border-radius;
            box-shadow: $box-shadow-lg;

            .items {
                margin: 0;
                padding: 0;
                flex-direction: column;

                .item {
                    &.current {
                        a {
                            border-color: $secondary;
                        }

                        strong {
                            border-color: $secondary;
                            font-weight: $font-weight-normal;
                        }
                    }

                    a,
                    strong {
                        width: 100%;
                        color: $primary;

                        &:visited {
                            color: $primary;
                        }
                    }

                    .delimiter {
                        display: none;
                    }
                }
            }
        }
    }
}