[data-content-type="banner"] {
    padding: calc(#{$grid-gutter-width} * .5) 0;
    height: 100%;

    &[data-appearance="collage-right"] {
        .pagebuilder-overlay {
            position: relative;

            &:before {
                content: "";
                background-image: url("../images/pagebuilder/boog.svg");
                background-repeat: no-repeat;
                background-size: cover;
                background-position: left 1px bottom;
                position: absolute;
                top: 0;
                bottom: 0;
                width: 110px;
                right: 100%;
            }
        }

        [data-element="content"] {
            text-align: left;
        }
    }

    [data-element="link"] {
        height: 100%;
        display: block;
    }
}

.pagebuilder-banner-wrapper {
    border-radius: $border-radius-lg;
    height: 100%;
    overflow: hidden;

    .pagebuilder-overlay {
        height: inherit;
        padding: 0 2rem;
        display: inline-block;

        .pagebuilder-collage-content {
            height: inherit;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: start;
        }
    }
}
