html {
    scroll-padding-top: 150px;
    overflow: auto;
}

body {
    overflow-x: clip;
}

.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    @include media-breakpoint-between(xs,md) {
        padding: 0 15px !important;
    }
}

@function get-breakpoints($key: "md") {
    @return map-get($container-max-widths, $key);
}

.container-fluid {
    max-width: get-breakpoints("xl");
}

.grecaptcha-badge {
    display: none !important;
}
