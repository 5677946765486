.nav-before-open {
    .page-header {
        left: 0;
        transition: left 0.3s;
    }
}

.nav-open {
    .page-header {
        left: calc(100% - 54px);
    }

    .nav-toggle {
        &:before {
            font-size: 3.5rem;
            top: -42px;
            right: 5px;

            @include media-breakpoint-between(xs, md) {
                background: $primary;
                color: #ffffff;
                content: '\e616';
                font-size: 3.5rem;
                height: 54px;
                line-height: 54px;
                position: absolute;
                text-align: center;
                top: -15px;
                width: 54px;
                z-index: 11;
            }
        }
    }
}

.page-header {
    .nav-sections {
        margin-bottom: 0;

        .nav-sections-items {
            @include media-breakpoint-between(xs, md) {
                background-color: $light;
            }

            @include media-breakpoint-up(md) {
                border-top: 1px solid #dee2e6;
                border-bottom: 1px solid #dee2e6;
                min-height: 65px;
            }

            .nav-sections-item-title {
                @include media-breakpoint-between(xs, md) {
                    padding: 1rem;
                    width: 50%;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &:first-child {
                    border-right: 1px solid #eee;
                }

                &.active {
                    background-color: #fff;
                    border-bottom: 1px solid transparent;
                }

                &:not(.active) {
                    border-bottom: 1px solid #eee;
                }

                .nav-sections-item-switch {
                    font-weight: 600;
                    font-size: 1.5rem;

                    &:active {
                        color: inherit;
                        text-decoration: none;
                    }
                }
            }

            .nav-sections-item-content {
                @include media-breakpoint-between(xs, md) {
                    padding: 0;
                    background-color: #fff;
                    margin-top: 0;

                    &:not(.drilling) {
                        margin-top: 50px;

                        .drilldown-sub {
                            display: none;
                        }
                    }

                    .drilldown {
                        position: relative;

                        .btn-drilldown {
                            background-color: $light;
                            padding: .5rem .3rem;

                            .btn-back {
                                display: flex;
                                align-items: center;
                                text-decoration: none;

                                &:active {
                                    color: inherit;
                                    text-decoration: none;
                                }

                                &:before {
                                    content: '\e617';
                                    font-family: 'icons-blank-theme';
                                    font-size: 2rem;
                                }
                            }
                        }

                        .drilldown-container {
                            .parent-item {
                                display: block;
                                color: #fff;
                                background-color: $primary;
                                padding: .75rem 1rem;
                            }

                            .drilldown-root {
                                padding: 0;
                                margin: 0;
                                list-style: none;

                                li {
                                    margin: 0;
                                    border-bottom: 1px solid $light;

                                    &.link {
                                        display: none;
                                    }

                                    > .mega {
                                        display: block;
                                        padding: .75rem 1rem;
                                        font-size: 1.5rem;
                                        color: inherit;

                                        &.active {
                                            background-color: lighten($primary, 70%);
                                        }
                                    }
                                }
                            }

                            .contact-block {
                                display: none;
                            }
                        }

                        .child-content-inner {
                            width: 100% !important;

                            .mega-col {
                                width: 100% !important;
                            }

                            ul {
                                list-style: none;
                                padding: 0;
                                margin: 0;

                                &.level2 {
                                    display: none;
                                }

                                li {
                                    margin: 0;
                                    border-bottom: 1px solid $light;

                                    > .mega {
                                        display: block;
                                        padding: .75rem 1rem;
                                        font-size: 1.5rem;
                                        color: inherit;

                                        &:hover {
                                            text-decoration: none;
                                        }

                                        &.has-child {
                                            &:after {
                                                content: '\e61c';
                                                font-family: 'icons-blank-theme';
                                                display: inline-block;
                                                font-weight: normal;
                                                overflow: hidden;
                                                right: 10px;
                                                position: absolute;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                > .ub-mega-menu {
                    li.has-child {
                        .child-content {
                            .child-content-inner {
                                ul.level1 {
                                    a.has-child,
                                    span.has-child {
                                        padding: 0;
                                        font-size: 1.6rem;
                                        font-weight: 600;
                                    }
                                }

                                ul.level2 {
                                    li {
                                        &.group {
                                            > .mega {
                                                font-weight: 600;
                                                font-size: 1.5rem;
                                            }
                                        }

                                        .level3 {
                                            list-style: none;
                                            padding: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        #store\.menu {
            @include media-breakpoint-up(md) {
                max-width: 1450px;
                margin: 0 auto;
                padding: 0 20px;
                display: flex !important;
            }
        }

        #store\.links {
            @include media-breakpoint-up(md) {
                display: none !important;
            }

            .navbar {
                padding: 0 !important;
                display: block;
                width: 100%;

                li {
                    border-bottom: 1px solid #f8f9fa;

                    a {
                        display: block;
                        padding: .75rem 1rem;
                        font-size: 1.5rem;
                        color: inherit;
                    }
                }
            }
        }

        @include media-breakpoint-up(md) {
            .dropdown-wrapper {
                .dropdown-menu-wrapper {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    right: 0;
                    display: none;
                    box-shadow: 0 2rem 3rem rgba(0, 0, 0, 0.175);
                    max-height: calc(100vh - 350px);
                    height: 100vh;

                    &.active {
                        display: block;
                    }

                    .dropdown-menu__inner {
                        list-style: none;
                        margin: 0;
                        width: 100%;
                        height: 100%;
                        overflow: auto;
                        overflow-x: hidden;
                        padding: 20px;

                        @include media-breakpoint-up(xxl) {
                            padding: 0;
                        }

                        .ub-mega-menu {
                            height: 100%;

                            &.level0 {
                                display: block;

                                @include media-breakpoint-up(xxl) {
                                    margin-left: calc((100% - 1450px - -40px) / 2);
                                    padding: 0;
                                    position: relative;
                                }
                            }

                            li.has-child {
                                &.d-none {
                                    display: block !important;
                                }

                                &:first-child {
                                    @include media-breakpoint-up(xxl) {
                                        padding-top: 20px;
                                    }
                                }

                                &:hover,
                                &.hover {
                                    text-decoration: none;
                                    background-color: #fff;

                                    > a {
                                        &:after {
                                            display: block;
                                        }
                                    }

                                    .child-content {
                                        display: block;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .ub-mega-menu {
            display: block;
            list-style: none;
            padding: 0;

            @include media-breakpoint-between(xs, md) {
                margin-bottom: 0;
            }

            &.level0 {
                @include media-breakpoint-up(md) {
                    display: flex;
                    justify-content: space-between;
                    margin: 0;
                    width: 100%;
                }

                @include media-breakpoint-up(xxl) {
                    padding-left: calc((100% - #{map-get($container-max-widths, xxl)} - -40px) / 2);
                }

                .child-content-inner {
                    @include media-breakpoint-up(md) {
                        max-width: $layout__max-width;
                        margin: 0 auto;
                        max-height: 100%;
                    }
                }
            }

            &.dropdown-visible {
                @include media-breakpoint-up(md) {
                    max-width: calc(100% - 150px);
                }
            }

            li.has-child {
                margin-bottom: 0;

                @include media-breakpoint-between(xs, md) {
                    border-bottom: 1px solid $light;
                }

                @include media-breakpoint-up(md) {
                    align-items: center;
                    display: flex;
                }

                .menu-group-link {
                    display: none;
                }

                &:first-child {
                    padding-left: 0;
                }

                &:hover {
                    background-color: $light;
                }

                > .child-content {
                    @include media-breakpoint-between(xs, md) {
                        display: none;
                    }

                    &.active {
                        @include media-breakpoint-up(md) {
                            display: block;
                        }
                    }
                }

                > a {
                    @include media-breakpoint-up(md) {
                        padding-left: .75rem !important;
                        padding-right: .75rem !important;
                    }

                    &:hover {
                        text-decoration: none !important;
                    }
                }

                a {
                    color: #2b353b;

                    @include media-breakpoint-between(xs, md) {
                        display: block;
                        padding: .75rem 1rem;
                        font-size: 1.5rem;;
                    }

                    @include media-breakpoint-up(md) {
                        font-size: 1.4rem;
                    }

                    &.has-child {
                        @include media-breakpoint-up(md) {
                            display: flex;
                            align-items: center;
                            height: 100%;
                        }

                        &:after {
                            @include media-breakpoint-between(xs, md) {
                                content: '\e61c';
                                font-family: 'icons-blank-theme';
                                display: inline-block;
                                font-weight: normal;
                                overflow: hidden;
                                right: 10px;
                                position: absolute;
                            }
                        }
                    }
                }
            }
        }

        .child-content {
            @include media-breakpoint-up(md) {
                display: none;
                position: absolute;
                left: 0;
                right: 0;
                top: calc(100% + -1px);
                box-shadow: 0 2rem 3rem rgba(0, 0, 0, 0.175);
                max-height: calc(100vh - 350px);
                background-color: #fff;
            }

            @extend .border-bottom;
            @extend .border-top;

            &.cols2 {
                .child-content-inner {
                    @include media-breakpoint-up(md) {
                        column-count: 2;
                        display: flex;
                        flex-wrap: wrap;
                    }
                }
            }

            &.cols3 {
                .child-content-inner {
                    @include media-breakpoint-up(md) {
                        column-count: 3;
                        display: flex;
                        flex-wrap: wrap;
                    }
                }
            }

            &.cols4 {
                .mega-col {
                    @include media-breakpoint-between(md, lg) {
                        width: 33.333% !important;
                    }
                }

                .child-content-inner {
                    @include media-breakpoint-up(md) {
                        column-count: 4;
                        display: flex;
                        flex-wrap: wrap;
                    }
                }
            }

            .child-content-inner {
                .level1 {
                    list-style: none;

                    li {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.ub-sub-menu-opened {
    .ub-bg-overlay {
        opacity: 1;
    }
}

.ub-bg-overlay {
    transition: all 0.15s ease-in-out;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100;
}

@include media-breakpoint-up(md) {
    .ub-mega-menu {
        .cols1 {
            ul.level1 {
                width: 340px;
            }
        }

        ul {
            list-style: none;
        }

        ul.level1 {
            padding-left: 0;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 360px;
                width: 1px;
                background-color: $gray-300;
            }

            > li.mega {
                &:not(:last-child) {
                    > a.mega {
                        margin-bottom: 0.5rem;
                    }
                }

                &:hover {
                    > a.mega {
                        background-color: $gray-200;
                    }
                }

                &.link {
                    > a.mega.link {
                        background-color: transparent;
                        width: auto;
                        padding: 0 !important;
                        font-weight: 600 !important;
                        margin-bottom: 1rem;
                        display: inline-block;
                        width: 100%;
                        line-height: 1;
                        color: #000;

                        @include media-breakpoint-up(md) {
                            font-size: 2rem !important;
                        }

                        @include media-breakpoint-up(lg) {
                            font-size: 2.5rem !important;
                        }
                    }
                }

                > a.mega {
                    display: inline-block;
                    position: relative;
                    background-color: $gray-100;
                    border-radius: $border-radius;
                    font-size: 1.5rem !important;
                    padding: 0.75rem 1rem !important;
                    font-weight: 400 !important;
                    width: 320px !important;

                    &:hover {
                        text-decoration: none;
                    }

                    &.has-child {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .menu-title {
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }

                        &:after {
                            content: "";
                            mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.502' height='14.502' viewBox='0 0 8.502 14.502'%3E%3Cpath id='Path_89_-_Outline' data-name='Path 89 - Outline' d='M5,.749a1.242,1.242,0,0,1,.885.367l6,6a1.25,1.25,0,0,1,0,1.769l-6,6a1.251,1.251,0,0,1-1.769-1.769L9.232,8,4.116,2.884A1.251,1.251,0,0,1,5,.749Z' transform='translate(-3.749 -0.749)'/%3E%3C/svg%3E");
                            background-color: $body-color;
                            width: 8px;
                            height: 10px;
                            mask-size: contain;
                            mask-repeat: no-repeat;
                        }
                    }
                }

                &.group {
                    &:hover {
                        ul.level2 {
                            opacity: 1;
                            pointer-events: all;
                            bottom: 0;
                        }
                    }
                }

                ul.level2 {
                    position: absolute;
                    left: 360px;
                    top: 0;
                    opacity: 0;
                    pointer-events: none;
                    padding: 2rem 0 2rem 2rem;

                    li.mega {
                        margin-bottom: 0.5rem;

                        &:hover {
                            > a {
                                text-decoration: underline;
                            }
                        }

                        &.group {
                            &:hover {
                                ul.level3 {
                                    opacity: 1;
                                    pointer-events: all;

                                    li.mega {
                                        &.group {
                                            &:hover {
                                                ul.level4 {
                                                    opacity: 1;
                                                    pointer-events: all;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        a {
                            font-size: 1.5rem;
                            font-weight: 400;

                            &.has-child {
                                &:after {
                                    content: "";
                                    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.502' height='14.502' viewBox='0 0 8.502 14.502'%3E%3Cpath id='Path_89_-_Outline' data-name='Path 89 - Outline' d='M5,.749a1.242,1.242,0,0,1,.885.367l6,6a1.25,1.25,0,0,1,0,1.769l-6,6a1.251,1.251,0,0,1-1.769-1.769L9.232,8,4.116,2.884A1.251,1.251,0,0,1,5,.749Z' transform='translate(-3.749 -0.749)'/%3E%3C/svg%3E");
                                    background-color: $body-color;
                                    width: 8px;
                                    height: 10px;
                                    mask-size: contain;
                                    mask-repeat: no-repeat;
                                    margin-left: 0.5rem;
                                }
                            }
                        }
                    }
                }

                ul.level3,
                ul.level4 {
                    opacity: 0;
                    pointer-events: none;
                    position: absolute;
                    left: 100%;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    padding: 2rem 0 2rem 4rem !important;

                    @include media-breakpoint-up(xs) {
                        display: none;
                    }

                    @include media-breakpoint-up(lg) {
                        display: block;
                    }

                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        bottom: 0;
                        left: 2rem;
                        width: 1px;
                        height: 100%;
                        background-color: #dee2e6;
                    }
                }

                ul {
                    &.level2,
                    &.level3,
                    &.level4 {
                        .group {
                            .has-child {
                                .menu-title {
                                    font-weight: 400;
                                }
                            }
                        }
                    }
                }
            }

            @include media-breakpoint-up(xs) {
                li.contact-block {
                    display: none;
                }
            }

            @include media-breakpoint-up(xxl) {
                li.contact-block {
                    display: block;
                    position: absolute;
                    background-color: $gray-100;
                    width: 20%;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    white-space: normal;

                    .card {
                        background-color: transparent;
                        border: 0;
                        height: 100%;

                        img {
                            max-height: 250px;
                            width: auto;
                            z-index: 1;
                            margin-top: auto;
                            position: absolute;
                            right: 0;
                            bottom: 0;
                        }

                        .card-body {
                            position: relative;

                            &__inner {
                                strong {
                                    font-size: 1.8rem;
                                    line-height: 1.2;
                                    padding-right: 1rem;
                                }
                            }
                        }

                        .card-footer {
                            background-color: $gray-100;
                        }
                    }

                    span {
                        display: block;

                        &.menu-title {
                            display: none;
                        }
                    }

                    &:after {
                        content: "";
                        background-color: $gray-100;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 100%;
                        top: 0;
                    }

                    &:before {
                        content: "";
                        background-image: url('../images/branding/beeldmerk.svg');
                        position: absolute;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background-size: contain;
                        background-repeat: no-repeat;
                        opacity: 0.8;
                        background-position: bottom;
                    }
                }
            }
        }
    }


    .child-content {
        overflow-x: hidden;

        .child-content-inner {
            padding: 20px;
            position: relative;
            min-height: 350px;
            align-content: flex-start;

            .mega-col {
                width: auto !important;
                margin-right: 2rem;

                ul.level1 {
                    &:before {
                        display: none;
                    }
                }

                > ul.level2 {
                    position: relative;
                }
            }
        }
    }

    .dropdown-menu-wrapper {
        background-color: #fff;

        .ub-mega-menu {
            padding-top: 2rem;
            padding-bottom: 2rem;

            > li.has-child {
                margin-bottom: 0.5rem !important;

                &:hover,
                &.mega-hover {
                    > a.has-child {
                        background-color: $gray-200;
                    }
                }

                > a.has-child {
                    background-color: $light;
                    display: inline-block;
                    position: relative;
                    font-size: 1.4rem;
                    padding: 0.75rem 1rem !important;
                    font-weight: 400;
                    width: 250px;
                    justify-content: space-between;
                    border-radius: 0.5rem;

                    &:after {
                        content: "";
                        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.502' height='14.502' viewBox='0 0 8.502 14.502'%3E%3Cpath id='Path_89_-_Outline' data-name='Path 89 - Outline' d='M5,.749a1.242,1.242,0,0,1,.885.367l6,6a1.25,1.25,0,0,1,0,1.769l-6,6a1.251,1.251,0,0,1-1.769-1.769L9.232,8,4.116,2.884A1.251,1.251,0,0,1,5,.749Z' transform='translate(-3.749 -0.749)'/%3E%3C/svg%3E");
                        background-color: $body-color;
                        width: 8px;
                        height: 10px;
                        mask-size: contain;
                        mask-repeat: no-repeat;
                        margin-left: 0.5rem;
                    }
                }
            }

            ul.level1 {
                &:before {
                    display: none;
                }

                > li.mega {
                    ul.level2 {
                        padding-left: 0;
                    }

                    ul {
                        &.level3,
                        &.level4 {
                            padding-left: 2rem;

                            &:before {
                                display: none;
                            }
                        }
                    }
                }
            }

            li.contact-block {
                display: none !important;
            }

            .child-content {
                border-top: 0 !important;
                border-bottom: 0 !important;
                border-left: 1px solid #dee2e6;
                position: absolute;
                top: 0;
                right: 0;
                box-shadow: none;
                overflow: auto;
                padding-right: calc((100% - #{map-get($container-max-widths, xxl)}) / 2);

                @each $name in $breakpoints-query {
                    @include media-breakpoint-down(#{$name}) {
                        padding-right: calc((100% - #{map-get($container-max-widths, #{$name})}) / 2);
                    }
                }

                @include media-breakpoint-up(md) {
                    left: 290px;
                    max-height: unset;
                    height: 100%;
                }

                @include media-breakpoint-up(xxl) {
                    left: 270px;
                }

                .child-content-inner {
                    max-width: unset !important;

                    .mega-col {
                        ul.drilldown-sub {
                            padding: 0;
                            list-style: none;

                            li.mega {
                                > .has-child {
                                    padding: 0;
                                }

                                ul.level2 {
                                    list-style: none;

                                    li.mega {
                                        margin: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
