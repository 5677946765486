.sidebar-main {
    overflow: auto;

    .filter {
        margin-bottom: 0;

        .filter-title {
            color: $dark;
            margin-bottom: 1rem;
        }

        .filter-subtitle {
            display: none;
        }

        .filter-current {
            .filter-current-subtitle {
                display: none;
            }

            .items {
                padding: 0;
                margin-bottom: 1rem;

                .item {
                    margin-bottom: 0.2rem;
                    font-size: 1.5rem;

                    .amshopby-remove {
                        &:before,
                        &:after {
                            background-color: $danger;
                        }
                    }

                    .amshopby-filter-name {
                        margin-right: 0.5rem;
                        color: $dark;
                    }
                }
            }
        }

        .filter-actions {
            .filter-clear {
                color: $primary;
                text-decoration: underline;
                font-size: 1.5rem;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        .filter-options {
            padding: 0;

            .filter-options-title {
                color: $dark;
                margin-bottom: .5rem;
                font-size: 1.5rem;
                font-weight: 600;
                display: flex;
                align-items: center;

                .tooltip-attr {
                    display: flex;

                    &:hover {
                        i {
                            background-color: #000;
                        }
                    }
                }

                .tooltip {
                    mask-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' height='18px' width='18px' focusable='false' data-prefix='fas' data-icon='info-circle' class='svg-inline--fa fa-info-circle fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'%3E%3C/path%3E%3C/svg%3E");
                    opacity: 1;
                    width: 15px;
                    height: 15px;
                    mask-size: contain;
                    background-color: #cacaca;
                    position: relative;
                    margin-left: 4px;
                    z-index: 1;

                    img {
                        display: none;
                    }
                }
            }

            .filter-options-content {
                &:not(:last-child) {
                    margin-bottom: 10px;
                    padding-bottom: 10px;
                    border-bottom: 1px solid #dee2e6;
                }

                .amshopby-slider-container {
                    margin-bottom: 0.5rem;
                }

                .am-filter-items-attr_price {
                    @include media-breakpoint-down(sm) {
                        max-width: unset;
                    }
                }

                .am-shopby-form {
                    padding: 0;
                }

                .swatch-attribute {
                    display: flex;
                    flex-wrap: wrap;

                    .am-swatch-wrapper {
                        margin-bottom: 0 !important;

                        &:last-child {
                            .swatch-option {
                                margin-right: 0 !important;
                            }
                        }

                        input {
                            &.-hidden {
                                display: none;
                            }
                        }
                    }

                    .swatch-option {
                        min-width: unset;
                        height: 25px;
                        width: 25px;
                        margin: 0 5px 0 0;

                        &:hover {
                            &.color {
                                &:not(.disabled) {
                                    border-color: #bdbdbd;
                                    outline: 0;
                                }
                            }
                        }

                        &.selected {
                            border-color: #bdbdbd;
                            outline: 0;
                        }

                        &.selected + .am-shopby-swatch-label {
                            color: $secondary;
                        }
                    }
                }

                .items {
                    margin: 0;
                    padding: 0;

                    .am-slider {
                        .ui-slider-handle {
                            background-color: $primary !important;
                        }
                    }

                    .item {
                        position: relative;

                        [class*="am-filter-item"] {
                            padding-left: 20px;
                            display: inline-block;
                        }

                        a {
                            font-size: 1.5rem;
                            color: $body-color;

                            @include media-breakpoint-down(sm) {
                                padding-left: 25px;
                            }

                            .count {
                                color: #a8a8a8;
                            }

                            &:hover {
                                color: $secondary;

                                ~ .amshopby-choice {
                                    &:before,
                                    &:hover {
                                        border-color: $secondary;
                                    }
                                }

                                .count {
                                    color: $secondary;
                                }
                            }
                        }

                        .amshopby-choice {
                            word-wrap: break-word;
                            word-break: break-word;
                            position: absolute;
                            top: 5px;
                            left: 0;
                            display: block;
                            margin: 0;
                            min-height: 12px;
                            line-height: 1.2;
                            cursor: pointer;

                            &:before {
                                border-color: #e3e3e3;
                            }

                            &:after {
                                background: $secondary url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAxNSAxNSI+CiAgPGRlZnMvPgogIDxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0xMS42NjkzIDMuNTAwNjFjLS4yMDc4LjAwNjk1LS40MDQ4LjA5ODg1LS41NDkzLjI1NjItMS45NTcwMyAyLjA2NDE1LTMuNDM4ODMgMy43NzY1NS01LjI4MDQxIDUuNzQ3ODNMMy44MTczIDcuNzA0NzRjLS4wNzk5Mi0uMDcxNi0uMTcyNDUtLjEyNTk1LS4yNzIzMy0uMTU5OTItLjA5OTg3LS4wMzM5Ny0uMjA1MTItLjA0NjktLjMwOTc0LS4wMzgwNi0uMTA0NjIuMDA4ODUtLjIwNjU2LjAzOTI5LS4zLjA4OTYtLjA5MzQ1LjA1MDMtLjE3NjU2LjExOTQ4LS4yNDQ1OS4yMDM2LS4wNjgwNC4wODQxMS0uMTE5NjYuMTgxNTEtLjE1MTkzLjI4NjYyLS4wMzIyNy4xMDUxMS0uMDQ0NTUuMjE1ODgtLjAzNjE1LjMyNTk4LjAwODQxLjExMDExLjAzNzM0LjIxNzQuMDg1MTUuMzE1NzQuMDQ3ODEuMDk4MzMuMTEzNTUuMTg1NzkuMTkzNDguMjU3MzlMNS4zNzc3MSAxMS4yOThjLjE1MzE1LjEzNzkuMzUwMTkuMjA5OC41NTE2LjIwMTMuMjAxNDEtLjAwODYuMzkyMzEtLjA5NjkuNTM0NDQtLjI0NzNDOC42MTA3NCA4Ljk4NzU3IDEwLjE0MiA3LjE3NTQ0IDEyLjI1NiA0Ljk0NTc4Yy4xMTY1LS4xMTgzNy4xOTYtLjI3MTIuMjI4MS0uNDM4MjMuMDMyMS0uMTY3MDMuMDE1My0uMzQwMzgtLjA0ODQtLjQ5NzA5LS4wNjM2LS4xNTY3MS0uMTcwOS0uMjg5MzctLjMwNzgtLjM4MDQyLS4xMzY5LS4wOTEwNS0uMjk2OC0uMTM2MTktLjQ1ODYtLjEyOTQzeiIvPgo8L3N2Zz4K) center / 12px;
                                background-color: $secondary;
                            }

                            &:hover {
                                &:before {
                                    border-color: $secondary;
                                }
                            }
                        }
                    }

                    input[type="checkbox"] {
                        overflow: hidden;
                        clip: rect(0, 0, 0, 0);
                        margin: 0;
                        padding: 0;
                        width: 0;
                        height: 0;
                        border: 0;
                        opacity: 0;
                        position: absolute;

                        &:checked {
                            + .amshopby-choice {
                                &:after {
                                    content: "";
                                }
                            }
                        }

                        + .amshopby-choice {
                            &:after {
                                width: 15px;
                                height: 15px;
                                top: 0;
                                position: absolute;
                                left: 0;
                                display: inline-block;
                                box-sizing: border-box;
                                border-radius: 2px;

                                @include media-breakpoint-down(sm) {
                                    background-size: 12px;
                                    background-repeat: no-repeat;
                                }
                            }

                            &:before {
                                content: "";
                                width: 15px;
                                height: 15px;
                                border: 1px solid #e3e3e3;
                                background: #ffffff;
                                left: 0;
                                display: inline-block;
                                border-radius: 2px;
                                display: inline-block;
                                box-sizing: border-box;
                            }

                            &:checked {
                                &:before {
                                    border-color: $secondary;
                                }
                            }
                        }
                    }
                }

                .am-show-more {
                    letter-spacing: 0;
                    color: $secondary;
                    padding-left: 0;
                    font-size: 1.3rem;
                    display: none;
                    margin-top: 0;
                    padding-right: 2rem;
                    background-color: transparent;
                    border: 0;

                    &.-active {
                        display: inline-flex;
                        padding-bottom: 0;
                    }

                    &:before {
                        @include media-breakpoint-down(sm) {
                            width: 13px;
                        }
                    }

                    .am-counter {
                        opacity: 1;
                        font-weight: 500;
                        padding: 0;
                    }
                }

                .amshopby-slider-wrapper {
                    .amshopby-slider-container {
                        .am-slider {
                            .ui-slider-handle {
                                background-color: $secondary;
                            }
                        }
                    }
                }
            }
        }
    }
}

.page-main {
    .amasty-catalog-topnav {
        display: none;
    }
}

@include media-breakpoint-between(xs,md) {
    .catalog-category-view,
    .catalogsearch-result-index,
    .ambrand-index-index {
        &.page-with-filter {
            .sidebar-main {
                position: fixed;
                transform: translateX(100%);
                width: 80%;
                background: #fff;
                top: 0;
                bottom: 0;
                right: 0;
                z-index: 500;
                transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
                overflow: auto;
                padding: 15px;

                .filter-title {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    &:after {
                        content: "";
                        mask-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 25.3.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Laag_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='329px' height='329px' viewBox='0 0 329 329' style='enable-background:new 0 0 329 329;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cpath class='st0' d='M194.6,164.8L322.7,36.7c8.3-8.3,8.3-21.8,0-30.1c-8.3-8.3-21.8-8.3-30.1,0L164.5,134.6L36.4,6.5 c-8.3-8.3-21.8-8.3-30.1,0c-8.3,8.3-8.3,21.8,0,30.1l128.1,128.1L6.3,292.9c-8.3,8.3-8.3,21.8,0,30.1c4.2,4.2,9.6,6.2,15.1,6.2 s10.9-2.1,15.1-6.2l128.1-128.1L292.6,323c4.2,4.2,9.6,6.2,15.1,6.2c5.5,0,10.9-2.1,15.1-6.2c8.3-8.3,8.3-21.8,0-30.1L194.6,164.8z' /%3E%3C/svg%3E%0A");
                        background-color: #000;
                        mask-size: contain;
                        width: 15px;
                        height: 15px;
                        cursor: pointer;
                    }
                }
            }

            &.filters-open {
                overflow: hidden;

                .sidebar-main {
                    transform: translateX(0);
                    box-shadow: 0 0px 0px 500px rgba(0, 0, 0, 0.6);
                }
            }
        }
    }
}

.ui-helper-hidden-accessible {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
