.page-title-wrapper {
    .order-date {
        margin-top: 0;
    }
}

.column {
    .order-links {
        padding: 0;
    }

    .block-order-details-view {
        dl {
            padding: 0;
        }
    }
}

.account {
    .page-main {
        padding-top: 0;
    }

    .page {
        &.messages {
            margin: 0;
        }        
    }

    .column {
        &.main {
            .table-wrapper {
                table {
                    tbody {
                        tr {
                            @include media-breakpoint-between(xs,md) {
                                border-bottom: 1px solid #e6e6e6;
                            }

                            td {
                                .item-options {
                                    margin: 0;
                                    padding: 0;
                                    font-size: 1.4rem;

                                    dd {
                                        margin-bottom: 1rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .toolbar {
                .pager {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .limiter {
                    display: flex;
                    align-items: center;

                    .limiter-label {
                        display: none;
                    }

                    .limiter-text {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}