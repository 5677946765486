@import "https://use.typekit.net/ymu8kdm.css";

// Mixins
@import "mixins/media-queries";

// Vendor libs
@import 'vendor/normalize/lib'; // Normalize.css
@import 'vendor/magento-ui/lib'; // Magento UI

// Theme variables
@import "layout/theme-variables";
@import 'variables';

// Reset default styles with magento-reset
@import 'blocks/reset';

// Theme blocks
@import 'blocks/actions-toolbar';
@import 'blocks/breadcrumbs';
@import 'blocks/buttons';
@import 'blocks/extends';
@import 'blocks/forms';
@import 'blocks/icons';
@import 'blocks/layout';
@import 'blocks/loaders';
@import 'blocks/messages';
@import 'blocks/navigation';
@import 'blocks/pages'; // Theme pager
@import 'blocks/popups';
@import 'blocks/price';
@import 'blocks/sections';
@import 'blocks/tables';
@import 'blocks/tooltips';
@import 'blocks/typography';

// Components styles (modal/sliding panel)
@import 'blocks/components/modals'; // from lib
@import 'blocks/components/modals_extend'; // local

// Modules
@import '../Magento_AdvancedCheckout/styles/module';
@import '../Magento_Braintree/styles/module';
@import '../Magento_Bundle/styles/module';
@import '../Magento_Catalog/styles/module';
@import '../Magento_CatalogEvent/styles/module';
@import '../Magento_CatalogSearch/styles/module';
@import '../Magento_Checkout/styles/module';
@import '../Magento_Customer/styles/module';
@import '../Magento_Downloadable/styles/module';
@import '../Magento_GiftCard/styles/module';
@import '../Magento_GiftCardAccount/styles/module';
@import '../Magento_GiftMessage/styles/module';
@import '../Magento_GiftRegistry/styles/module';
@import '../Magento_GiftWrapping/styles/module';
@import '../Magento_GroupedProduct/styles/module';
@import '../Magento_Invitation/styles/module';
@import '../Magento_LayeredNavigation/styles/module';
@import '../Magento_Msrp/styles/module';
@import '../Magento_MultipleWishlist/styles/module';
@import '../Magento_Multishipping/styles/module';
@import '../Magento_Newsletter/styles/module';
@import '../Magento_Paypal/styles/module';
@import '../Magento_ProductVideo/styles/module';
@import '../Magento_Review/styles/module';
@import '../Magento_Reward/styles/module';
@import '../Magento_Rma/styles/module';
@import '../Magento_Sales/styles/module';
@import '../Magento_SalesRule/styles/module';
@import '../Magento_SendFriend/styles/module';
@import '../Magento_Swatches/styles/swatches';
@import '../Magento_Theme/styles/module';
@import '../Magento_Vault/styles/module';
@import '../Magento_Weee/styles/module';
@import '../Magento_Wishlist/styles/module';
@import '../Magento_PageBuilder/styles/module';

// Widgets
@import '../Magento_AdvancedCheckout/styles/widgets';
@import '../Magento_Banner/styles/widgets';
@import '../Magento_Catalog/styles/widgets';
@import '../Magento_CatalogEvent/styles/widgets';
@import '../Magento_Cms/styles/widgets';
@import '../Magento_MultipleWishlist/styles/widgets';
@import '../Magento_VersionsCms/styles/widgets';

// Bootstrap
@import "layout/bs-variables";
@import "vendors/bootstrap/bootstrap";

// Theme
@import "layout/main";
@import "layout/icons";
@import "layout/minicart";
@import "layout/header";
@import "layout/footer";
@import "layout/navigation";
@import "layout/loop";
@import "layout/typography";
@import "layout/grid";
@import "layout/filters";
@import "layout/cms-page";
@import "layout/checkout-default";
@import "layout/form";
@import "layout/item";
@import "layout/custom";
@import "layout/cart";
@import "layout/sidebar";
@import "layout/modal";
@import "layout/messages";
@import "layout/account";
@import "layout/kiyoh-reviews";
@import "layout/minisearch";
@import "layout/search";
@import "layout/amsearch";
@import "layout/amform";
@import "layout/quotation-modal";

// Assets
@import "assets/cookie-preferences";

// Custom modules
@import '../Media101_NotificationModule/styles/styles';
@import '../Media101_WhatsAppChat/styles/styles';
@import '../Media101_ModalAddToCart/styles/styles';

@import '../Magento_PageBuilder/web/scss/module';
