// Variables
// check https://github.com/twbs/bootstrap/blob/main/scss/_variables.scss for all the options

$enable-responsive-font-sizes:  true;
$enable-negative-margins:       true;

$primary:                       #14336C;
$secondary:                     #F5A622;
$success:                       #12ac74;
$info:                          #0dcaf0;
$warning:                       #E58303;
$danger:                        #dc3545;
$error:                         #ff0000;
$dark:                          #343e40;
$light:                         #f8f9fa;
$gray-100:                      #f8f9fa;
$gray-200:                      #e9ecef;
$gray-300:                      #dee2e6;
$gray-400:                      #ced4da;
$gray-500:                      #adb5bd;
$gray-600:                      #6c757d;
$gray-700:                      #495057;
$gray-800:                      #343a40;
$gray-900:                      #212529;

$theme-colors: (
    "primary":          $primary,
    "secondary":        $secondary,
    "success":          $success,
    "info":             $info,
    "warning":          $warning,
    "danger":           $danger,
    "error":            $error,
    "dark":             $dark,
    "light":            $light,
    "gray-100":         $gray-100,
    "gray-200":         $gray-200,
    "gray-300":         $gray-300,
    "gray-400":         $gray-400,
    "gray-500":         $gray-500,
    "gray-600":         $gray-600,
    "gray-700":         $gray-700,
    "gray-800":         $gray-800,
    "gray-900":         $gray-900,
);

// Body
$body-bg:                        $light;
$body-color:                     #51596c;
$min-contrast-ratio:             1;

// Typography
$font-size-base:                1.6rem;
$font-family-base:              'neuzeit-grotesk', sans-serif;
$font-weight-normal:            400;
$link-decoration:               none;
$text-muted:                    #878787;

// Grid
$container-padding-x:           2rem;

// Shadow
$box-shadow-lg:                 0 .375rem 1.5rem 0 rgba(140,152,164,.125);

// Headings
$h1-font-size:                  $font-size-base * 2.3;
$h2-font-size:                  $font-size-base * 2;
$h3-font-size:                  $font-size-base * 1.75;
$h4-font-size:                  $font-size-base * 1.5;
$h5-font-size:                  $font-size-base * 1.25;
$h6-font-size:                  $font-size-base * 1.1;
$headings-font-weight:          700;
$headings-margin-bottom:        1rem;
$headings-color:                #343e40;

// Buttons
$btn-font-size:                 1.5rem;
$btn-padding-y:                 0.7rem;
$btn-padding-x:                 1.7rem;
$btn-focus-width:               0;
$btn-link-color:                $dark;

// Alerts
$alert-border-radius:           0;
$alert-bg-scale:                0%;
$alert-border-scale:            0%;
$alert-color-scale:             100%;

// Offcanvas
$offcanvas-horizontal-width:    450px;

// Border radius
$border-radius:                 .5rem;
$border-radius-sm:              .5rem;
$border-radius-lg:              1.25rem;

// Z-index
$zindex-sticky:                 1;

// Tables
$table-striped-bg:              #f5f5f5;

// Containers
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1450px,
    xxl: 1450px
);

// Ratios
$aspect-ratios: (
    "1x1": 100%,
    "4x3": calc(3 / 4 * 100%),
    "16x9": calc(9 / 16 * 100%),
    "21x9": calc(9 / 21 * 100%),
    "6x2": calc(2 / 6 * 100%),
    "9x2": calc(2 / 9 * 100%)
);


// Custom array's
$breakpoints-query:
( xxl ),
( xl ),
( lg ),
( md ),
( sm ),
( xs );
