body {
    &.cms-index-index,
    &.cms-home,
    &.cms-page-view {
        background-color: #fff;

        .page-main {
            max-width: 100%;
            padding: 0;
        }
    }

    &.page-layout-cms-full-width {
        .page-main {
            max-width: 100% !important;
        }
    }
}

[data-content-type="row"] {
    ~ [data-content-type="row"] {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }
}

// Amasty brand slider
.am-widget-brand-slider {
    .amslider-header-container {
        padding-top: 0;
        font-size: $h4-font-size;
        font-weight: $font-weight-bold;
        text-align: center;
        margin-bottom: 1rem;
    }

    .ambrands-slider-container {
        margin-bottom: 2.5rem;
    }

    .ambrands-slider {
        position: relative;

        .swiper-button-prev,
        .swiper-button-next {
            background-color: transparent;
            border: 0;

            &:after {
                font-size: 2rem;
            }
        }

        .swiper-wrapper {
            align-items: center;

            .swiper-slide {
                display: flex;
                justify-content: center;

                .ambrands-image {
                    max-height: 100px;
                }
            }
        }
    }
}

//Kiyoh widget slider
.kiyoh-widget-slider {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    padding: 3rem;
    box-shadow: $box-shadow-lg;

    h3 {
        display: flex;
        align-items: center;

        &:before {
           content: "";
           background-image: url("../images/customer-service/kiyoh-beeldmerk.png");
           width: 30px;
           height: 30px;
           background-size: contain;
           margin-right: 0.5rem;
        }
    }

    .kiyoh-widget-list {
        padding: 0;
    }

    .kiyoh-widget-item {
        margin-bottom: 0;

        .short {
            height: auto;
        }

        .long {
            line-height: 1.7;
            max-height: 70px;
            font-size: 1.3rem;
        }

        .name {
            &:before {
                content: "-";
            }
        }
    }

    .kiyoh-widget-item-wrapper {
        background-color: transparent;
        box-shadow: none;
        padding: 0;

        .kiyoh-widget-item_top {
            align-items: center;

            > span {
                display: none;
            }

            span {
                margin: 0;
            }
        }
    }

    .kiyoh-widget-footer {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex: 1;
        margin: 0;
        font-size: 1.2rem;
        color: #000;

        a {
            color: #000;
        }

        span:last-of-type {
            display: none;
        }

        b:last-child {
            display: none;
        }
    }
}


@include media-breakpoint-only(xs) {
    .row-type-banners {
        width: calc(100vw - 20px);

        .pagebuilder-column-group {
            scroll-snap-type: x mandatory;
            display: flex;
            overflow-x: scroll;
            flex-wrap: nowrap;

            &:first-of-type {
                padding-left: 10px;
            }

            .pagebuilder-column {
                width: auto !important;
                flex-direction: row !important;
                padding: 0;

                [data-content-type="banner"] {
                    min-width: calc(100vw - 60px);
                    scroll-snap-align: start;
                    padding: 0 calc(1.5rem * 1) 0 0;

                    .pagebuilder-poster-overlay {
                        min-height: 220px !important;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-between(sm,lg) {
    .row-type-banners {
        .pagebuilder-column-group {
            .pagebuilder-column {
                flex-direction: row !important;

                [data-content-type="banner"] {
                    flex: 1;

                    .pagebuilder-poster-overlay {
                        min-height: 350px !important;
                    }
                }
            }
        }
    }
}

.cms-blog {
    .page-title-wrapper {
        text-align: center;
    }
}

.row-type-blog {
    .pagebuilder-column-group {
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
        border-radius: 0.5rem;
        border: 1px solid #dee2e6 !important;
        margin: 0;

        [data-content-type="image"] {
            margin: 0;
            padding: 0;
            border: 0 !important;
        }

        [data-content-type="column"] {
            padding: 0;

            &[data-appearance="align-center"] {
                align-self: center !important;
                padding: 1.5rem;
            }

            [data-content-type="button-item"] {
                div {
                    font-size: 15px;
                    padding: 10px 15px;
                }
            }

        }
    }
}


.page-layout-cms-full-width {
    h3 {
        font-size: 1.8rem;
    }

    h4 {
        font-size: 1.4rem;
    }

    p {
        font-size: 1.6rem;
    }
}

@include media-breakpoint-between(xs, md) {
    .row-type-categories {
        > [data-content-type="column-group"] {
            &:first-of-type {
                > [data-content-type="column"] {
                    &:first-of-type {
                        > [data-content-type="image"] {
                            border-top: 0 !important;
                        }
                    }
                }
            }
        }

        [data-content-type="column"] {
            padding: 0;

            [data-content-type="image"] {
                padding: 0.5rem 1.5rem;
                margin: 0;
                border-top: 1px solid $gray-300 !important;

                [data-element="link"] {
                    aspect-ratio: 1 / 1;
                    display: flex;
                    align-items: center;
                }

                img.pagebuilder-mobile-only {
                    height: 50px;
                }
            }
        }
    }
}

.row-type-reviews {
    .kiyoh-widget-slider {
        background-color: #fff;
    }
}

@include media-breakpoint-between(xs, md) {
    .row-type-reviews {
        .pagebuilder-column {
            background-image: none !important;
        }

        [data-content-type="text"] {
            margin-left: 0 !important;

            h2 span {
                font-size: 2rem !important;
            }

            table {
                width: 100% !important;

                img {
                    height: auto !important;
                }
            }
        }
    }
}


.am-widget-brand-slider {
    overflow: hidden;
}

.row-type-usps {
    .pagebuilder-column-group {
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
        border-radius: 0.5rem;
        border: 1px solid #dee2e6 !important;
        margin: 0;

        [data-content-type="image"] {
            margin: 0;
            padding: 0;
            border: 0 !important;
            box-shadow: none;
            width: 100px;
        }

        [data-content-type="column"] {
            flex-direction: row !important;
            align-items: center;
            padding-top: 2rem;
            padding-bottom: 2rem;

            &[data-appearance="align-center"] {
                align-self: center !important;
                padding: 1.5rem;
            }

            [data-content-type="button-item"] {
                div {
                    font-size: 15px;
                    padding: 10px 15px;
                }
            }

            [data-content-type="text"] {
                margin-left: 1rem;
                h5 {
                    margin: 0;
                }
            }
        }
    }
}
