.page-main {
    .page-title {
        font-weight: 600;
        margin-bottom: 0;
    }

    h1, h2, h3, h4, h5, h6 {
        font-weight: 600;
    }

    h2 {
        font-size: 2.5rem;
    }

    h3 {
        font-size: 2.2rem;
    }

    p {
        line-height: 1.7;
    }

    strong {
        font-weight: 600;
    }

    ol, ul, dl {
        margin-bottom: 2rem;
        padding-left: 15px;
    }

    ul, ol {
        > li {
            margin-bottom: .5rem;
        }
    }
}


.cms-page-view {
    &:not(.cms-merk) {
        background-color: $white;

        .page-header {
            position: relative;
            top: unset;
        }

        .page-main {
            max-width: 950px;
        }
    }
}



/* Brand page
========================================= */
.cms-merk {
    .page-main {
        .content {
            .ambrands-letters-list {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -15px;

                .ambrands-letter {
                    width: auto;
                    padding: 0 15px !important;

                    @include media-breakpoint-up(xs) {
                        width: 50%;
                        margin-bottom: 1.5rem;
                    }

                    @include media-breakpoint-up(md) {
                        width: 25%;
                        margin-bottom: 3rem;
                    }

                    dd {
                        margin: 0;
                    }

                    .bare-list {
                        justify-content: center;
                        margin: 0;

                        .ambrands-image {
                            max-width: 140px;
                            max-height: 70px;
                        }
                    }
                }

                .brand-item {
                    margin: 0;
                    width: 100%;
                    max-width: unset;
                    border: 1px solid $light;
                    transition: .3s ease;

                    &:hover {
                        box-shadow: 0 1px 10px 0 rgba(96, 96, 96, 0.2);
                    }

                    .item {
                        padding: 1rem;

                        .label {
                            margin: 0;
                            padding: 0;
                            font-weight: 500;

                            &:before {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Contact page
========================================= */
.contact-index-index {
    #contact-form {
        min-width: unset;
        width: 100%;

        .fieldset {
            .legend {
                margin: 0 0 1rem 0;
                font-weight: 600;
            }

            .note {
                &:before {
                    display: none;
                }
            }
        }
    }

    .contact-details {
        strong {
            display: inline-block;
            min-width: 75px;
        }

        a {
            color: #fff;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .social-media {
        i {
            font-size: 2rem;
        }
    }
}

/* Page: Customer service
========================================= */
.page-layout-customer-service {
    .page-header {
        margin: 0;
    }
}

/* Page: 404
========================================= */
.page-layout-404 {
    .page-header {
        margin-bottom: 0;
    }

    .page-title-wrapper {
        text-align: center;
        margin-bottom: 1.5rem;
        margin-top: 0;
    }

    .page-main {
        position: relative;
        border-radius: $border-radius-lg;

        @include media-breakpoint-up(xs) {
            padding: 2rem 15px;
            margin-bottom: 2rem;
        }

        @include media-breakpoint-up(md) {
            padding: 3rem;
            background-color: #fff;
            margin-top: -10rem;
            max-width: 65vw;
            box-shadow: $box-shadow-lg;
        }

        @include media-breakpoint-up(lg) {
            max-width: 55vw;
        }

        @include media-breakpoint-up(xl) {
            margin-top: -15rem;
            max-width: 45vw;
            margin-bottom: 5rem;
        }

        .main {
            padding: 0;

            dl {
                padding: 0;
            }
        }
    }
}
