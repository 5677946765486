.page-header {
    position: sticky;
    z-index: 400;
    background: #fff;
    border: 0;

    @include media-breakpoint-up(xs) {
        top: -38px;
        margin-bottom: 1rem;
    }

    @include media-breakpoint-up(md) {
        top: -37px;
        margin-bottom: $indent__base;
    }

    .header-top {
        .panel {
            @include media-breakpoint-between(xs,md) {
                padding: 0 !important;
            }

            .kiyoh-widget-plain {
                order: 2;
                font-size: $small-font-size;
                display: flex;
                align-items: center;
                position: relative;

                .kiyoh-link {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    z-index: 2;
                }

                &:before {
                    content: "";
                    background-image: url("../images/customer-service/kiyoh-beeldmerk.png");
                    width: 20px;
                    height: 20px;
                    background-size: contain;
                    margin-right: 0.5rem;
                }

                @include media-breakpoint-between(xs,md) {
                    justify-content: center;
                }

                @include media-breakpoint-up(md) {
                    margin-left: 1.5rem;
                }

                @include media-breakpoint-up(lg) {
                    margin-left: 3rem;
                }

                .rating-result {
                    &:before {
                        font-size: 25px;
                        letter-spacing: -7px;
                    }

                    > span {
                        &:before {
                            color: $secondary;
                            font-size: 25px;
                            letter-spacing: -7px;
                        }
                    }
                }

                div:not([class]) {
                    span {
                        display: none;
                    }

                    b {
                        &:after {
                            content: "/10";
                        }

                        &:last-child {
                            display: none;
                        }
                    }
                }

                a {
                    display: none;
                }
            }

            .usps {
                order: 1;
                align-items: center;

                li {
                    @include media-breakpoint-between(xs,md) {
                        display: none;
                    }
                }

                &.slick-initialized {
                    .slick-track {
                        display: flex;
                        align-items: center;
                    }

                    .slick-slide {
                        @include media-breakpoint-between(xs,md) {
                            display: block;
                        }
                    }
                }
            }

            .navbar {
                order: 3;
            }
        }
    }

    .panel {
        background-color: $light;

        .header {
            @include media-breakpoint-up(xs) {
                padding: .75rem 15px;
            }

            @include media-breakpoint-up(md) {
                padding: 1rem 20px;
            }
        }

        &:before,
        &:after {
            display: none !important;
        }

        .links {
            @include media-breakpoint-up(md) {
                display: block;
            }

            ul {
                padding: 0;
                margin: 0;
                display: flex;
                overflow: hidden;
                list-style: none;

                li {
                    margin-bottom: 0;

                    &:not(:first-child) {
                        margin-left: 1.2rem;
                    }

                    a {
                        color: $dark;
                        font-size: 1.4rem;

                        &:hover,
                        &.active {
                            color: $primary;
                        }
                    }
                }
            }
        }
    }

    .header {
        @include media-breakpoint-up(xs) {
            padding: .75rem 15px 0;
        }

        @include media-breakpoint-up(md) {
            padding: 1rem 20px;
        }

        &:before,
        &:after {
            display: none !important;
        }

        .logo {
            margin: 0;
            float: none;

            @include media-breakpoint-down(sm) {
                margin-right: 20px !important;
            }

            @include media-breakpoint-up(xs) {
                max-width: 85px;
            }

            @include media-breakpoint-up(sm) {
                max-width: 125px;
            }

            @include media-breakpoint-up(lg) {
                max-width: 40%;
            }
        }

        .left-col {
            .nav-toggle {
                position: relative;
                left: 0;
                top: 0;
                width: 44px;
                height: 34px;
            }
        }

        .center-col {
            @include media-breakpoint-down(md) {
                order: 3;
                width: 100%;
            }
        }

        .right-col {
            @include media-breakpoint-down(md) {
                order: 2;
                align-items: center;
            }

            .links {
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    margin: 0;

                    @include media-breakpoint-up(lg) {
                        height: 48px;
                        overflow: hidden;
                    }
                }
            }

            .cta-link {
                &:hover {
                    text-decoration: none;

                    i {
                        background-color: $secondary;
                    }

                    .label {
                        color: $secondary;
                    }
                }

                .label {
                    font-family: $font-family-name__base;
                    font-size: 1.3rem;
                    font-weight: 600;
                    color: #000;
                    margin-top: .5rem;
                    white-space: nowrap;
                }
            }
        }
    }

    .header-bottom {
        .nav-sections {
            @include media-breakpoint-down(md) {
                position: fixed;
                background-color: #fff;
                overflow-y: auto;
            }
        }
    }
}
