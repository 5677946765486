.block-search {
    float: none;
    padding: 0;
    width: 100%;
    margin-top: 10px;

    @include media-breakpoint-up(md) {
        max-width: 100%;
    }

    @include media-breakpoint-up(lg) {
        max-width: 75%;
        margin: 0 auto;
    }

    .label {
        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .minisearch {
        .control {
            position: relative;

            @include media-breakpoint-between(xs,md) {
                margin: 0 -15px 0;
                background-color: darken($light, 2);
                border: 0;
                border-bottom: 1px solid #dee2e6;
                padding: 0 10px;
            }

            @include media-breakpoint-up(md) {
                padding: 0;
            }

            input {
                box-shadow: 0 1px 3px 0 rgba(96,96,96,0.2);

                @include media-breakpoint-down(md) {
                    position: relative;
                    left: 0;
                    border: 0;
                    margin: 8px 0;
                }

                &:focus {
                    border-color: $primary;
                    outline: 0;
                }
            }

            button {
                padding: 0;
                top: 12px;
                opacity: 1;
                right: 15px;

                @include media-breakpoint-down(md) {
                    background-color: transparent;
                    border: 0;
                    top: 18px;
                    right: 25px;
                    display: block;
                    position: absolute;

                    i {
                        width: 20px;
                        height: 20px;
                    }
                }

                &:before {
                    display: none;
                }
            }
        }

        .input-text {
            border: 1px solid #e3e3e3;
            border-radius: .5rem;
            padding-left: 15px;

            &::placeholder {
                opacity: 1;
                color: #b7b9ba;
            }

            @include media-breakpoint-down(md) {
                font-size: 1.4rem;
            }

            @include media-breakpoint-up(xs) {
                height: calc(2.2em + .75rem + 2px);
            }

            @include media-breakpoint-up(md) {
                height: calc(3em + .75rem + 2px);
            }
        }
    }
}
