#notice-cookie-block {
    position: fixed;
    z-index: 99999999;
    bottom: 15px;
    right: 15px;
    left: unset;
    max-width: 40rem;
    background-color: rgba($primary, .9);
    backdrop-filter: blur(10px);
    padding: 0.2rem;
    border-radius: $border-radius-lg;
    box-shadow: $box-shadow-lg;

    .content {
        &:before {
            content: "🍪 Cookies";
            color: $white;
            font-weight: bold;
            font-size: 1.9rem;
            margin-bottom: 1rem;
            display: block;
        }

        p {
            color: $white;
            line-height: 1.4;
        }

        strong {
            display: block;
            font-size: 1.4rem;
            color: $white;
        }

        a {
            color: $white !important;
            text-decoration: underline;
        }
    }

    .actions {
        margin-top: 1rem;

        #btn-cookie-allow {
            @extend .btn-sm;
            @extend .btn-secondary;
            padding: .75rem 1rem;
        }
    }

    @include media-breakpoint-down(sm) {
        left: 15px;
        max-width: calc(100% - 30px);

        p {
            font-size: 95%;
        }
    }
}
