[data-content-type="row"][data-appearance="contained"] {
    @include media-breakpoint-up(xs) {
        padding-left: 15px;
        padding-right: 15px;
    }

    @include media-breakpoint-up(md) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.page-layout-cms-full-width {
    [data-content-type="row"][data-appearance="contained"] {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}
