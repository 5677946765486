.page-header {
    .header {
        .amsearch-wrapper-block {
            width: 100%;

            .amsearch-input-wrapper {
                width: 100%;
            }
        }
    }
}

.amsearch-wrapper-block {
    .amsearch-form-block {
        &.-opened {
            width: auto !important;
        }
    }

    .amsearch-input-wrapper {
        .amsearch-button.-close.-icon {
            background-size: 15px;
        }

        .amsearch-button.-icon {
            background-size: 20px;
        }

        .amsearch-button.-loupe {
            right: 10px;
        }
    }

    .amsearch-input {
        box-shadow: 0 1px 3px 0 rgba(96,96,96,0.2);
        height: calc(3em + .75rem + 2px);
        border: 1px solid #e3e3e3;
        border-radius: .5rem;

        @include media-breakpoint-down(md) {
            position: relative;
            left: 0;
            border: 0;
            margin: 8px 0;
        }

        &:focus {
            border-color: $primary;
            outline: 0;
        }
    }

    .amsearch-result-section {
        max-height: 80vh;

        .amsearch-block-header {
            margin-bottom: 1rem;

            .amsearch-title {
                text-transform: inherit;
                font-size: 1.5rem;
                font-family: $font-family-name__base;
                color: #000;
            }
        }

        .amsearch-item {
            text-transform: inherit;
            margin: 0;

            .amsearch-link {
                color: $body-color;
                padding: 5px 20px;

                &:hover {
                    color: $primary;
                }
            }
        }
    }
}

.amsearch-products-section {
    @include media-breakpoint-only(xs) {
        padding: 0 !important;
    }

    height: max-content;
    border-color: $gray-400 !important;

    .amsearch-block-header {
        margin-bottom: 0 !important;
        padding-bottom: 1rem !important;

        .amsearch-count {
            font-weight: 500;
        }
    }

    .amsearch-product-list {
        .product-item {
            margin: 0;
            align-items: start;
            padding: 1rem 20px;

            .amsearch-image {
                border: 1px solid $gray-400;
                border-radius: 0.5rem;
                overflow: hidden;
                max-width: 45px;
            }

            .amsearch-link {
                padding: 0;
            }

            .product-item-details {
                min-height: auto;
                padding-left: 1rem;

                .price-box {
                    margin-bottom: 0;
                    padding: 0;

                    .price-container {
                        display: flex;

                        span {
                            padding: 0 !important;
                            flex-basis: 0;
                        }
                    }

                    span {
                        padding: 0 !important;
                    }

                    .price-including-tax {
                        flex-basis: 0;
                        margin-right: 0.5rem;
                    }

                    .price-excluding-tax {
                        font-weight: 500;
                    }
                }
            }
        }
    }

    .amsearch-link {
        color: $primary !important;
    }
}

.amsearch-items-section {
    padding: 20px 0 !important;

    &:not(:first-child:last-child) {
        max-width: 25rem !important;
    }
}

.search.results {
    .amsearch-block-header {
        padding: 0;
    }
}
