.header-bottom {
    .minicart-wrapper {
        margin-left: 2rem;

        @include media-breakpoint-down(md) {
            margin-top: 0;
            margin-left: 1.5rem;
        }

        .showcart {
            display: flex;
            align-items: center;
            flex-direction: column;

            &:before {
                display: none !important;
            }

            .label {
                font-size: 1.3rem;
                color: #000;
                margin-top: .5rem;
            }

            .showcart {
                .label {
                    margin-top: 0.2rem;
                }
            }

            .counter {
                &.qty {
                    background-color: $success;
                    position: absolute;
                    border-radius: 20px;
                    color: #fff;
                    min-width: 21px;
                    height: 21px;
                    padding: 3px;
                    margin: 0;
                    font-size: 1.1rem;
                    top: -10px;
                    border: 2px solid #fff;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @include media-breakpoint-up(xs) {
                        right: -10px;
                    }

                    @include media-breakpoint-up(md) {
                        right: 15px;
                    }
                }
            }
        }
        #offcanvasRight {
            &.show {
                transform: none;
                visibility: visible;
            }
        }

        .ui-dialog {
            display: block !important;
            height: 100%;
        }

        .block-minicart {
            display: block;
            position: inherit;

            &:before {
                right: 50px;
            }

            &:after {
                right: 49px;
            }

            #top-cart-btn-checkout {
                background-color: #ffd814;
                border-color: #ffd814;
                color: #333 !important;
                border-radius: 0.5rem;

                &:hover {
                    background-color: #e7c415;
                    border-color: #e7c415;
                    text-decoration: none;
                }
            }

            #minicart-content-wrapper {
                height: calc(100vh - 50px);

                .btn-quotation {
                    background-color: #ffa41c;
                    border-color: #ffa41c;
                    color: #fff;

                    &:hover {
                        background-color: #de8b0f;
                        border-color: #de8b0f;
                        text-decoration: none;
                    }
                }

                #btn-minicart-close {
                    display: none;
                }

                .subtitle {
                    &.empty {
                        padding: 0;
                        font-weight: 500;
                        font-size: 1.3rem;
                    }
                }

                .minicart-items-wrapper {
                    height: auto !important;

                    .minicart-items {
                        .product-item {
                            a {
                                color: #000;
                                font-weight: 500;
                            }
                        }
                    }
                }

                .viewcart {
                    display: inline-block;
                    padding: .75rem 1.5rem;
                    background-color: $light;
                    border-radius: .5rem;
                    color: #000;
                    font-weight: 500;

                    &:hover {
                        background-color: #dfdcdc;
                    }
                }
            }

            .alert {
                border-radius: 0.5rem;
                color: #fff;
                font-size: 1.5rem;

                &:hover {
                    text-decoration: none;
                    background: #082354;
                }
            }
        }

        .minicart-widgets {
            margin: 0;
        }
    }
}
