figure[data-content-type='image'] {
    position: relative;
    overflow: hidden;

    @include media-breakpoint-up(xs) {
        padding: 2rem 1.5rem;
    }

    @include media-breakpoint-up(md) {
        padding: 2.5rem 1.5rem;
        box-shadow: $box-shadow-sm;
        border-radius: $border-radius-sm;
        border: 1px solid $gray-300 !important;
    }

    [data-element="link"] {
        @extend .stretched-link;
        flex-shrink: 0;
    }

    &:has(> [data-element="link"]) {
        &:hover {
            background-color: $primary;

            img {
                mix-blend-mode: multiply;
            }

            figcaption {
                color: #fff;
            }
        }
    }

    figcaption {
        font-weight: $font-weight-bold;
        color: $dark;
        text-align: left;

        @include media-breakpoint-only(xs) {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        @include media-breakpoint-up(xs) {
            font-size: calc(1.301rem + 0.612vw);
            margin-left: 1rem;
        }

        @include media-breakpoint-up(md) {
            font-size: $h6-font-size;
            margin-left: 1.5rem;
        }
    }
}

[data-appearance="align-center"] {
    align-self: stretch !important;

    figure[data-content-type='image'] {
        display: flex;
        align-items: center;
        flex-grow: 1;

        [data-element="link"] {
            @include media-breakpoint-up(xs) {
                width: 15%;
            }

            @include media-breakpoint-up(md) {
                width: 30%;
            }
        }
    }
}

body {
    &.page-layout-cms-full-width {
        figure[data-content-type='image'] {
            padding: 0;
            border: 0 !important;
            margin: 0;

            img {
                width: 100%;
            }
        }
    }
}


