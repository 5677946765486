.checkout-index-index {
    #announcement {
        display: none !important;
    }
}

.nav-before-open {
    #announcement {
        transition: all 0.3s;
    }
}

.nav-open {
    #announcement {
        left: calc(100% - 54px);
    }
}

#announcement {
    z-index: 300;
    left: 0;

    @include media-breakpoint-between(xs, md) {
        font-size: 1.4rem;
    }

    button {
        background-color: transparent;
        padding: 0;
        border: 0;

        &:hover {
            opacity: 0.75;
        }
    }
}