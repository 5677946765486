[data-content-type="row"][data-appearance="full-width"] {
    [data-element="inner"] {
        padding: 0 20px;
    }
}

.page-layout-cms-full-width {
    [data-content-type="row"][data-appearance="full-width"] {
        [data-element="inner"] {
            padding-top: 2.5rem;
            padding-bottom: 2.5rem;
        }
    }
}
