@mixin make-pb-col($size: false, $columns: $grid-columns) {
    @if $size {
        flex: 0 0 auto;
        width: percentage(divide($size, $columns)) !important;
    }
}

.pagebuilder-column {
    padding: 0 calc(#{$grid-gutter-width} * 1);

    @include media-breakpoint-between(xs,md) {
        @for $i from 1 through $grid-columns {
            &[data-columns-mobile="#{$i}"] {
                @include make-pb-col($i, $grid-columns);
            }
        }
    }

    @include media-breakpoint-between(md,lg) {
        @for $i from 1 through $grid-columns {
            &[data-columns-tablet="#{$i}"] {
                @include make-pb-col($i, $grid-columns);
            }
        }
    }
}

.page-layout-cms-full-width {
    .pagebuilder-column {
        &[data-appearance="align-center"] {
            align-self: center !important;
        }
    }
}

