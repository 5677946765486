.page-footer {
    > .footer.content {
        border: 0;
        margin: 0;
        padding: 0;
    }
}

.footer-top {
    ul {
        li {
            font-size: 1.3rem;
            display: flex;
            align-items: center;
        }
    }
}

.footer-main {
    overflow: hidden;

    @include media-breakpoint-down(sm) {
        .g-0-xs {
            margin-right: 0;
            margin-left: 0;

            > .col,
            > [class*="col-"] {
                padding-right: 0;
                padding-left: 0;
            }
        }
    }

    .footer {
        @include media-breakpoint-down(sm) {
            padding-bottom: 0;
        }
    }

    &__inner {
        .customer-service-img {
            max-height: 320px;
        }

        @include media-breakpoint-up(md) {
            border-bottom: 1px solid rgba($white, 0.1) !important;
        }

        .block-contact-details {
            .logo-footer {
                img {
                    width: 170px;
                    filter: brightness(0) invert(1);
                }
            }
        }

        .logo-quality-mark {
            width: 100px;
        }
    }

    .customer-service-img {
        max-height: 320px;
    }

    .contact-item {
        &__icon {
            @include media-breakpoint-down(sm) {
                i {
                    width: 17px;
                    height: 17px;
                }
            }

            @include media-breakpoint-up(xs) {
                width: 30px;
                height: 30px;
            }

            @include media-breakpoint-up(md) {
                width: 40px;
                height: 40px;
            }
        }

        .flex-1 {
            flex: 1;
        }

        a {
            .svg-icon {
                width: 6px;
                height: 12px;
            }

            &:hover {
                color: $secondary !important;
            }
        }
    }

    .footer-menu {
        @include media-breakpoint-down(sm) {
            border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        }

        &.footer-menu--brands {
            ul {
                @include media-breakpoint-up(md) {
                    display: flex;
                    flex-wrap: wrap;
                }

                li {
                    @include media-breakpoint-up(md) {
                        width: 25%;
                    }
                }
            }
        }

        h5 {
            @include media-breakpoint-down(sm) {
                font-size: 1.7rem;
            }

            i {
                transition: .3s ease;
            }

            &.open {
                i {
                    transform: rotate(90deg);
                }
            }

            ~ p {
                display: none;
            }
        }

        ul {
            @include media-breakpoint-between(xs,md) {
                display: none;
            }

            &.open {
                @include media-breakpoint-between(xs,md) {
                    display: block;
                }
            }

            li {
                margin-bottom: .5rem;

                a {
                    color: #fff;

                    &:hover {
                        text-decoration: underline !important;
                    }
                }
            }
        }

        .ambrands-brandlist-widget {
            @include media-breakpoint-between(xs,md) {
                display: none;
            }

            &.open {
                @include media-breakpoint-between(xs,md) {
                    display: block;
                }
            }
        }

        .ambrands-brandlist-widget {
            .ambrands-letters-list {
                width: auto;
                padding: 0;
                display: block;

                @include media-breakpoint-up(xs) {
                    column-count: 2;
                }

                @include media-breakpoint-up(md) {
                    column-count: 4;
                }

                @include media-breakpoint-up(lg) {
                    column-count: 5;
                }
            }

            .ambrands-letter {
                width: auto;
                padding: 0;
                margin: 0;
                break-inside: avoid;

                .ambrands-title {
                    display: none;
                }

                .ambrands-brand-item {
                    max-width: unset;
                    text-align: left;

                    .ambrands-inner {
                        background: transparent;
                        box-shadow: none;
                        display: inline-flex;
                        color: #fff;
                        border: 0;
                    }

                    .ambrands-label {
                        padding: 0;
                        margin: 0;
                        font-weight: 400;
                        font-size: 1.6rem;
                    }
                }

                .ambrands-content {
                    margin: 0;
                }
            }
        }
    }
}

.footer-bottom {
    .payment-methods {
        margin: 0 -0.25rem;

        li {
            padding: 0 .25rem;

            img {
                width: 30px;
            }
        }
    }
}
