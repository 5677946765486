.checkout-cart-index {
    .page-title-wrapper {
        .page-title {
            margin-bottom: 0;
        }
    }

    .crosssell {
        margin-top: 3.5rem;

        .title {
            margin: 0;

            h2 {
                margin-bottom: 2rem !important;

                @include media-breakpoint-between(xs,md) {
                    font-size: 2rem;
                }
            }
        }

        .products {
            &.wrapper {
                .product-items {
                    margin: 0;

                    @include media-breakpoint-between(xs,md) {
                        overflow-x: scroll;
                        flex-wrap: nowrap;
                    }

                    .product-item {
                        @include media-breakpoint-between(xs,md) {
                            min-width: 80vw;
                            margin-right: 1rem;
                            padding-right: 1rem;
                            border-right: 1px solid #e8ecf0;
                        }

                        @include media-breakpoint-up(md) {
                            width: 25%;
                        }

                        .product-item-info {
                            @include media-breakpoint-between(xs,md) {
                                border: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.cart-container {
    @include media-breakpoint-between(xs,md) {
        display: flex;
        flex-direction: column;
        background-color: $white;
        box-shadow: $box-shadow-lg;
        border-radius: $border-radius-sm;
    }

    .form-cart {
        @include media-breakpoint-between(xs,md) {
            order: 1;
        }
    }

    .cart {
        &.table-wrapper {
            @include media-breakpoint-up(md) {
                background-color: $white;
                box-shadow: $box-shadow-lg;
            }

            #shopping-cart-table {
                tr {
                    th {
                        padding: 0.5rem;
                        border-bottom: 0;
                        color: #aaa;

                        span {
                            font-size: 1.5rem;
                            font-weight: $font-weight-normal;
                        }
                    }

                    &.item-info {
                        td {
                            border: 0;

                            &.col {
                                &::before {
                                    @include media-breakpoint-between(xs,md) {
                                        display: none;
                                    }
                                }
                            }
                        }

                        .item {
                            padding: 0;

                            @include media-breakpoint-between(xs,md) {
                                display: flex;
                            }

                            .product-item-photo {
                                @include media-breakpoint-between(xs,md) {
                                    position: relative;
                                    max-width: 80px;
                                    top: 0;
                                }

                                .product-image-container {
                                    width: 80px !important;
                                    aspect-ratio: unset !important;

                                    .product-image-photo {
                                        width: 80px;
                                        height: 80px;
                                        object-fit: contain;
                                        margin: 0;
                                    }
                                }
                            }

                            .product-item-details {
                                padding: 0.5rem;
                                vertical-align: middle;

                                .item-options {
                                    font-size: 1.3rem;
                                }
                            }

                            .product-item-name {
                                margin: 0;
                                font-size: 1.5rem;

                                @include media-breakpoint-between(xs,md) {
                                    line-height: 1.2;
                                }
                            }

                            .product-item-sku {
                                display: block;
                                font-size: 1.3rem;
                            }
                        }

                        .qty {
                            &.control {
                                border: 1px solid #E2E2E2;
                                border-radius: $border-radius-sm;
                                overflow: hidden;

                                &:hover {
                                    border-color: lighten($primary, 50);
                                }

                                .input-text {
                                    height: auto;

                                    &:focus {
                                        box-shadow: none;
                                        outline: 0;
                                    }
                                }

                                button {
                                    background-color: transparent;
                                    border: 0;
                                    color: #adadba;

                                    &:hover {
                                        color: lighten($primary, 50);
                                    }

                                    &.decreaseQty {
                                        padding-right: 0.5rem;
                                        padding-left: 1rem;
                                    }

                                    &.increaseQty {
                                        padding-left: 0.5rem;
                                        padding-right: 1rem;
                                    }
                                }
                            }
                        }

                        .subtotal {
                            small {
                                font-weight: $font-weight-normal;
                                font-size: 1.3rem;
                                color: #aaa;

                                .price {
                                    font-size: 1.2rem;
                                    font-weight: $font-weight-normal;
                                    margin-right: 0.4rem;
                                }
                            }
                        }

                        .product-remove {
                            @include media-breakpoint-up(xs) {
                                padding-left: 1rem;
                            }

                            @include media-breakpoint-up(md) {
                                padding-left: 1.5rem;
                            }

                            i {
                                &:hover {
                                    background-color: $dark;
                                }
                            }

                            .action-edit {
                                display: none;
                            }
                        }
                    }
                }

                thead {
                    + .item {
                        border-top: 0;
                    }
                }

                tbody {
                    &.cart {
                        border-bottom: 1px solid #dee2e6;

                        &:last-of-type {
                            border: 0;
                        }

                        td {
                            border: 0;
                            padding-top: 0.5rem;

                            @include media-breakpoint-up(xs) {
                                vertical-align: top;
                            }

                            @include media-breakpoint-up(md) {
                                vertical-align: middle;
                            }
                        }
                    }
                }
            }
        }

        &.actions {
            @include media-breakpoint-between(xs,md) {
                display: none;
            }

            .action {
                padding: $btn-padding-y $btn-padding-x;
                border-radius: $border-radius-sm;
                font-size: $btn-font-size;
                color: $btn-link-color;
                line-height: unset;

                &:before {
                    display: none;
                }
            }
        }
    }

    .cart-summary {
        padding: 0 1.5rem 1.5rem;
        background-color: $white;
        margin: 0;

        @include media-breakpoint-between(xs,md) {
            order: 2;
        }

        @include media-breakpoint-up(md) {
            box-shadow: $box-shadow-lg;
            border-radius: $border-radius-sm;
        }

        .cart-totals {
            @include media-breakpoint-between(xs,md) {
                border: 0;
            }
        }

        .title {
            color: #aaa;

            @include media-breakpoint-between(xs,md) {
                display: none;
            }
        }

        .totals {
            tr,
            th,
            td {
                border-bottom: 0;
                padding: 0;
            }

            .shipping {
                .value {
                    display: block;
                    font-size: 1.4rem;
                    color: #aaa;
                }
            }

            .totals-tax {
                font-size: 1.3rem;

                .mark,
                .amount {
                    padding-top: 1rem;
                }
            }

            .grand {
                &.incl {
                    font-size: 1.6rem;

                    .mark {
                        strong {
                            font-weight: $font-weight-bold;
                        }
                    }

                    .amount {
                        color: #000;
                    }
                }

                &.excl {
                    display: none;
                }
            }
        }

        .checkout-methods-items {
            .checkout {
                border-radius: $border-radius;
            }

            .primary {
                background-color: #ffd814;
                border-color: #ffd814;
                color: #333;

                &:hover {
                    background-color: #e7c415;
                    border-color: #e7c415;
                    text-decoration: none;
                }
            }
        }

        .quotation-form {
            margin-top: 1.5rem;

            .btn-quotation {
                background-color: #ffa41c;
                border-color: #ffa41c;
                color: #fff;

                &:hover {
                    background-color: #de8b0f;
                    border-color: #de8b0f;
                    text-decoration: none;
                }
            }
        }
    }
}
