#quotationModalHome {
    .modal-content {
        border-radius: 0;

        .modal-header {
            border-radius: 0;

            .btn-close {
                background-color: #fff;
                border-radius: 100%;
                opacity: 1;
                background-size: 12px;
                margin: 0;
            }
        }

        .modal-title {
            color: #fff;
            font-size: 2rem;
            font-family: 'Arial Black';
            text-transform: uppercase;
        }

        .modal-body {
            font-family: 'Arial';
            padding-top: 1rem;
            padding-bottom: 1rem;

            h6 {
                font-family: 'Arial Black';
                color: #000;
            }

            p {
                font-size: 1.8rem;
                color: #000;

                strong {
                    color: #000;
                }
            }

            .quotation-ribbon {
                background-image: url('../images/ribbon.svg');
                background-repeat: no-repeat;
                background-size: contain;
                width: 10rem;
                height: 10rem;
                position: absolute;
                right: 2rem;
                top: 2rem;
                font-family: 'Arial Black';
                color: #fff;
                text-transform: uppercase;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.3rem;
                transform: rotate(15deg);
            }
        }

        .modal-footer {
            .btn {
                font-family: 'Arial Black';

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}
